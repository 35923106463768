import {useCallback, useEffect, useMemo} from 'react';
import {
  useCreateView,
  useCreateViewField,
  useDeleteView,
  useDeleteViewField,
  useGetViews,
  useGetViewsCache,
  useUpdateView,
  useUpdateViewField,
  useUpdateViewFieldOrder
} from '../../hooks/api';
import {useLocalStorageState, useMemoryState} from '../../hooks';

const defaultViewState = {
  changed: false,                                   // Memory
  density: 'standard',                              // Local, shared by all views
  filters: [],                                      // Backend
  parameters: {},                                   // Backend
  hidden: [],                                       // Backend
  page: 0,                                          // Memory
  pageSize: 25,                                     // Local, shared by all views
  pinned: {left: ['number'], right: ['actions']},   // Local
  search: '',                                       // Memory
  sort: {'number': -1},                             // Backend
  widths: {},                                       // Local
};

const _memoryState = {};
const _changedState = {};

export const useViewState = (entity, {viewLimit = 200, fieldLimit = 200, filterMap, homeViewOnly} = {}) => {
  const [sharedState, setSharedState] = useLocalStorageState(`${entity}|ViewState|Global`, {density: 'standard', pageSize: 25});
  const [localState, setLocalState] = useLocalStorageState(`${entity}|ViewState|Views`, {});
  const [memoryState, setMemoryState] = useMemoryState(_memoryState, entity, _memoryState[entity] ?? {page: 0, search: ''});
  const {data: {views: _viewsData}, isLoading} = useGetViews(entity);
  const viewsData = useMemo(() => (_viewsData && {
    ..._viewsData,
    views: _viewsData?.views.map((view) => ({...view, ...(view.filters && filterMap ? filterMap(view.filters) : {filters: view.filters})})),
  }), [_viewsData, filterMap]);
  const [changedState, setChangedState] = useMemoryState(_changedState, entity, _changedState[entity] ?? {});
  const {updateCache} = useGetViewsCache(entity);
  const {create: createViewApi} = useCreateView(entity, {updateCache});
  const {update: updateViewApi} = useUpdateView(entity, {updateCache});
  const {delete: deleteViewApi} = useDeleteView(entity, {updateCache});
  const {create: createFieldApi} = useCreateViewField(entity, {updateCache});
  const {update: updateFieldApi} = useUpdateViewField(entity, {updateCache});
  const {delete: deleteFieldApi} = useDeleteViewField(entity, {updateCache});
  const {update: updateFieldOrderApi} = useUpdateViewFieldOrder(entity, {updateCache});

  // The currentView is stored in local storage, which is almost permanent, since the user cannot fix an issue
  // by refreshing the page, let's make sure the currentView is valid!
  const [_currentViewId, setCurrentViewId] = useLocalStorageState(`${entity}|ViewState|currentView`, null);
  const homeViewId = viewsData?.views?.[0]?._id;
  const currentViewId = homeViewOnly ? homeViewId : viewsData?.views?.find((view) => view._id === _currentViewId)?._id ?? viewsData?.views?.[0]?._id ?? null;
  useEffect(() => {
    if (homeViewId && _currentViewId === null) {
      setCurrentViewId(homeViewId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeViewId]);

  const setCurrentView = useCallback((viewOrId) => setCurrentViewId(viewOrId?._id ?? viewOrId), [setCurrentViewId]);

  const getView = useCallback((viewId) => {
    const view = {
      ...defaultViewState,
      ...(viewsData?.views?.find(({_id}) => _id === viewId) ?? {}),
      ...sharedState,
      ...localState[viewId],
      ...memoryState,
      changed: false,
      ...(changedState[viewId] ?? {}),
      readonly: viewId === homeViewId,
    };
    Object.keys(defaultViewState).forEach((key) => {
      if (view[key] == null || (typeof view[key] === 'object') && Object.keys(view[key]).length === 0) {
        view[key] = defaultViewState[key];
      }
    });
    return view;
  }, [changedState, homeViewId, localState, memoryState, sharedState, viewsData?.views]);

  const currentView = useMemo(() => getView(currentViewId), [currentViewId, getView]);

  const addView = useCallback(async () => {
    try {
      if (currentViewId) {
        const newView = {...getView(currentViewId), name: 'New View', type: 'TABLE'};
        const res = await createViewApi({
          view: {
            _id: newView._id,
            name: newView.name,
            type: newView.type,
            filters: newView.filters,
            parameters: newView.parameters,
            sort: newView.sort,
            hidden: newView.hidden,
          }
        });
        newView._id = res?.views?.views?.at(-1)?._id;
        if (newView._id) {
          setLocalState((prev) => ({
            ...prev,
            [newView._id]: {pinned: newView.pinned, widths: newView.widths}
          }));
          setCurrentViewId(newView._id);
        }
        return newView._id;
      }
    } catch(err) {
      console.error('Error while adding a view', err);
    }
    return null;
  }, [createViewApi, currentViewId, getView, setCurrentViewId, setLocalState]);

  const deleteView = useCallback(async (viewId) => {
    try {
      if (viewId !== homeViewId) {
        if (viewsData?.views.find(({_id}) => _id === viewId)) {
          await deleteViewApi({id: viewId});
        }
      }
    } catch (err) {
      console.error('Error while deleting a view', err);
    }
  }, [homeViewId, viewsData, deleteViewApi]);

  const renameView = useCallback(async (viewId, name) => {
    try {
      if (viewId !== homeViewId) {
        return await updateViewApi({id: viewId, view: {name}});
      }
    } catch (err) {
      console.error('Error while renaming a view', err);
    }
    return null;
  }, [homeViewId, updateViewApi]);

  const saveView = useCallback(async (asNewView) => {
    try {
      const changes = {...changedState[currentViewId]};
      delete changes.changed;
      let viewId = currentViewId;
      if (changes && Object.keys(changes).length > 0) {
        if (asNewView || currentView.readonly) {
          viewId = await addView();
        } else {
          await updateViewApi({id: currentViewId, view: changes});
        }
      }
      setChangedState((prev) => {
        const newState = {...prev};
        delete newState[currentViewId];
        return newState;
      });
      return viewId;
    } catch(err) {
      console.error('Error while saving the view', err);
    }
    return undefined;
  }, [addView, changedState, currentView.readonly, currentViewId, setChangedState, updateViewApi]);

  const addField = useCallback(async (type, name, path, after) => {
    try {
      let position = viewsData.fields.findIndex((field) => field.path === path);
      if (position < 0) {
        position = viewsData.fields.length - 1;
      } else if (position === 0) {
          position = 1;
      } else if (after && position < viewsData.fields.length) {
        position += 1;
      }
      const res = await createFieldApi({field: {title: name, type}}, {params: {position}});
      return res.path;
    } catch(err) {
      console.error('Error while creating a field', err);
      return undefined;
    }
  }, [createFieldApi, viewsData?.fields]);

  const deleteField = useCallback(async (path) => {
    try {
      const fieldIndex = viewsData?.fields.findIndex((f) => f.path === path);
      if (fieldIndex >= 0) {
        await deleteFieldApi({id: path});
      }
    } catch(err) {
      console.error('Error while deleting a column', err);
    }
  }, [deleteFieldApi, viewsData?.fields]);

  const renameField = useCallback(async (path, name) => {
    try {
      const index = viewsData?.fields.findIndex((f) => f.path === path);
      if (index >= 0 && viewsData?.fields[index].title !== name) {
        // Update the cache immediately so that the name does not revert before changing to the new name
        const newField = {...viewsData.fields[index], title: name};
        const newFields = [...viewsData.fields];
        newFields[index] = newField;
        updateCache({views: {...viewsData, fields: newFields}});
        await updateFieldApi({id: path, field: {title: name}});
      }
    } catch(err) {
      console.error('Error while saving the column name', err);
    }
  }, [updateCache, updateFieldApi, viewsData]);

  const setFieldOptions = useCallback(async (path, options) => {
    try {
      const index = viewsData?.fields.findIndex((f) => f.path === path);
      if (index >= 0 && viewsData?.fields[index].options !== options) {
        // Update the cache immediately so that the name does not revert before changing to the new name
        const newField = {...viewsData.fields[index], options};
        const newFields = [...viewsData.fields];
        newFields[index] = newField;
        updateCache({views: {...viewsData, fields: newFields}});
        await updateFieldApi({id: path, field: {options}});
      }
    } catch(err) {
      console.error('Error while saving the column name', err);
    }
  }, [updateCache, updateFieldApi, viewsData]);

  const setFieldWidth = useCallback((path, width) => {
    setLocalState((prev) => ({
      ...prev,
      [currentViewId]: {
        ...prev[currentViewId],
        widths: {
          ...prev[currentViewId]?.widths,
          [path]: width,
        }
      }
    }));
  }, [currentViewId, setLocalState]);

  const setFieldOrder = useCallback(async (order) => {
    try {
      const fields = order.map((path) => viewsData.fields.find((field) => field.path === path));
      updateCache({views: {...viewsData, fields}});
      await updateFieldOrderApi({views: {fields}});
    } catch(err) {
      console.error('Error while saving the column order', err);
    }
  }, [updateCache, updateFieldOrderApi, viewsData]);

  const setPinnedFields = useCallback((pinned) => {
    setLocalState((prev) => ({
      ...prev,
      [currentViewId]: {
        ...prev[currentViewId],
        pinned: {
          left: viewsData?.fields.filter(({path}) => pinned.left?.includes(path)).map(({path}) => path),
          right: viewsData?.fields.filter(({path}) => pinned.right?.includes(path)).map(({path}) => path),
        },
      }
    }));
  }, [currentViewId, setLocalState, viewsData?.fields]);

  const setHiddenFields = useCallback((hidden) => {
    setChangedState((prev) => ({
        ...prev,
        [currentViewId]: {
          ...prev[currentViewId],
          changed: true,
          hidden,
        }
      }));
  }, [currentViewId, setChangedState]);

  const setDensity = useCallback((density) => {
    setSharedState((prev) => ({...prev, density}));
  }, [setSharedState]);

  const setPage = useCallback((page) => {
    setMemoryState((prev) => ({...prev, page}));
  }, [setMemoryState]);

  const setPageSize = useCallback((pageSize) => {
    setSharedState((prev) => ({...prev, pageSize}));
  }, [setSharedState]);

  const setSearch = useCallback((search) => {
    setMemoryState((prev) => ({...prev, search, page: 0}));
  }, [setMemoryState]);

  const setFilters = useCallback((filters) => {
    setChangedState((prev) => ({
      ...prev,
      [currentViewId]: {
        ...prev[currentViewId],
        changed: true,
        filters,
      }
    }));
    setMemoryState((prev) => ({...prev, page: 0}));
  }, [currentViewId, setChangedState, setMemoryState]);

  const setParameter = useCallback((parameter) => {
    setChangedState((prev) => {
      const newState = {
        ...prev,
        [currentViewId]: {
          ...prev[currentViewId],
          changed: true,
          parameters: {
            ...prev[currentViewId]?.parameters,
            ...parameter,
          }
        }
      };
      Object.entries(parameter).forEach(([key, value]) => {
        if (value == null) {
          delete newState[currentViewId].parameters[key];
        }
      });
      return newState;
    });
    setMemoryState((prev) => ({...prev, page: 0}));
  }, [currentViewId, setChangedState, setMemoryState]);

  const setSort = useCallback((sort) => {
    setChangedState((prev) => ({
      ...prev,
      [currentViewId]: {
        ...prev[currentViewId],
        changed: true,
        sort,
      }
    }));
  }, [currentViewId, setChangedState]);

  const allowAddViews = viewsData?.views.length <= viewLimit;
  const allowAddFields = viewsData?.fields.filter(({builtin}) => !builtin).length < fieldLimit;

  return useMemo(() => ({
    entity,
    isLoading,

    setDensity,

    allowAddViews,
    viewLimit,
    currentView,
    setCurrentView,
    views: viewsData?.views.map((view) => ({_id: view._id, name: view.name})) ?? [],
    addView,
    deleteView,
    renameView,

    allowAddFields,
    fieldLimit,
    fields: viewsData?.fields ?? [],
    addField,
    deleteField,
    renameField,
    saveView,
    setFieldOptions,
    setFieldOrder,
    setFieldWidth,
    setHiddenFields,
    setPinnedFields,

    page: memoryState.page,
    pageSize: sharedState.pageSize,
    setPage,
    setPageSize,

    search: memoryState.search,
    setParameter,
    setFilters,
    setSearch,
    setSort,

    apiState: {
      filters: currentView.filters,
      params: currentView.parameters,
      page: memoryState.page,
      pageSize: sharedState.pageSize,
      search: memoryState.search,
      sort: currentView.sort,
    }
  }), [
    addField,
    addView,
    allowAddFields,
    allowAddViews,
    currentView,
    deleteField,
    deleteView,
    entity,
    fieldLimit,
    isLoading,
    memoryState.page,
    memoryState.search,
    renameField,
    renameView,
    saveView,
    setCurrentView,
    setDensity,
    setFieldOptions,
    setFieldOrder,
    setFieldWidth,
    setFilters,
    setHiddenFields,
    setPage,
    setPageSize,
    setParameter,
    setPinnedFields,
    setSearch,
    setSort,
    sharedState.pageSize,
    viewLimit,
    viewsData?.fields,
    viewsData?.views
  ]);
};
