import React, {useCallback, useEffect, useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {Icon} from '@mui/material';
import {get} from 'lodash';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {snackError, snackSuccess} from '../../../actions/action-types';
import {draftInvoiceFromJob, getInvoice} from '../../../actions/invoice';
import {SET_JOB, setEmailType, UPSERT_JOBS} from '../../../actions/jobs';
import {openProofUploadModal, openSendProofModal, setInvoiceModalOnClose} from '../../../actions/modals';
import {editNewTask} from '../../../actions/tasks';
import {PermissionBasedContent, SubscriptionTypes, XeroPaymentInfoDialog} from '../../../componentsHoops';
import {GQL_DELETE_JOB} from '../../../queries/jobs';
import {hoopsQuerySetRefetch} from '../../../actions/hoopsQuery';
import {AttentionConfirmDialog, PopupItem, PopupMenuList, usePopupMenuContext} from '../../../componentsLib/Popovers';
import {BodyText} from '../../../componentsLib/Text';
import {Link} from '../../../componentsLib/Basic';
import {AddPrurchaseOrderModal, DecoratorSpecSheetModal} from './AddPurchaseOrderDecorationSpecModals';

const DUPLICATE = gql`
    mutation jobDuplicate($jobId: String!){
        jobDuplicate(jobId: $jobId){
            _id
        }
    }`;

export const JobsActionMenu = ({
  job = {},
  updateField = () => null,
  refetchSalesDocs = () => null,
}) => {
  const {closeMenu} = usePopupMenuContext();
  const [archived, setArchived] = useState(job?.archived);
  const [openDecoratorSpecSheetModal, setOpenDecoratorSpecSheetModal] = useState(false);
  const [openVoidedModal, setOpenVoidedModal] = useState(false);
  const [openJobCompletedModal, setOpenJobCompletedModal] = useState(false);
  const [xeroPaymentInfoDialogOpen, setXeroPaymentInfoDialogOpen] = useState(false);
  const [accountingConnected, setAccountingConnected] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openAddPo, setOpenAddPo] = useState(false);
  const handleAddPo = () => {
    dispatch({type: SET_JOB, payload: {...job, completed: true}});
    setOpenAddPo(true);
  };

  const dispatch = useDispatch();

  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);

  const [jobDuplicate] = useMutation(DUPLICATE, {
    onCompleted: () => {
      dispatch(snackSuccess(`Job ${job.number} duplicated.`));
      refetchSalesDocs();
    },
    onError: (err) => dispatch(snackError(err.message))
  });

  const [jobDelete] = useMutation(GQL_DELETE_JOB, {
    onCompleted: () => {
      dispatch(snackSuccess(`Job ${job.number} deleted.`));
      refetchSalesDocs();
    },
    onError: (err) => dispatch(snackError(err.message))
  });

  const pdfLink = `${process.env.REACT_APP_BASE_URL}/jobs/${job._id}/pdf`;
  const packingSlipLink = `${process.env.REACT_APP_BASE_URL}/jobs/${job._id}/packing-slip/pdf`;
  const shippingLabelLink = `${process.env.REACT_APP_BASE_URL}/jobs/${job._id}/shipping-label`;
  const invoicePdfLink = `${process.env.REACT_APP_BASE_URL}/rest/accounting/invoices/${(job.digest?.invoice ?? job.invoice)?._id}/pdf`;

  useEffect(() => {
    const refreshTokenExpiry = get(user, 'company.accountingPlatform.refreshTokenExpiry', 0);
    if (moment(refreshTokenExpiry, 'x').isAfter()) {
      setAccountingConnected(true);
    }
  }, [user]);

  const handleArchived = (newValue) => {
    setArchived(newValue);
    updateField({id: job._id, field: 'archived', value: newValue});
  };

  const handleCompletedMenuItem = async () => {
    setOpenJobCompletedModal(true);
  };
  const handleCompletedConfirmation = async (confirmed) => {
    setOpenJobCompletedModal(false);
    if (confirmed) {
      await updateField({id: job._id, field: 'completed', value: true});
      handleArchived(true);
      dispatch({type: SET_JOB, payload: {...job, completed: true}});
      dispatch({type: UPSERT_JOBS, payload: {...job, completed: true}});
      dispatch(hoopsQuerySetRefetch('job', true));
      refetchSalesDocs();
    }
  };

  const handleVoidedMenuItem = async () => {
    setOpenVoidedModal(true);
  };
  const handleVoidedConfirmation = async (confirmed) => {
    setOpenVoidedModal(false);
    if (confirmed) {
      await updateField({id: job._id, field: 'voided', value: true});
      dispatch({type: SET_JOB, payload: {...job, voided: true}});
      dispatch({type: UPSERT_JOBS, payload: {...job, voided: true}});
      dispatch(hoopsQuerySetRefetch('job', true));
      refetchSalesDocs();
    }
  };

  const handleUploadProof = () => {
    dispatch(openProofUploadModal(job._id));
  };

  const handleDuplicate = () => {
    jobDuplicate({variables: {jobId: job._id}});
  };

  const handleShowDecoratorSpecSheet = useCallback(() => {
    setOpenDecoratorSpecSheetModal(true);
  }, []);

  const handleCloseDecoratorSpecSheet = useCallback(() => {
    setOpenDecoratorSpecSheetModal(false);
    closeMenu();
  }, [closeMenu]);

  const handleAddTask = () => {
    dispatch(editNewTask({
      jobIds: [job._id],
      jobs: [job]
    }));
  };

  const handleSend = async () => {
    dispatch({
      type: SET_JOB,
      payload: job
    });
    dispatch(setEmailType('job'));
    dispatch(openSendProofModal({jobId: job._id}));
  };

  const handleShowConfirmDelete = useCallback(() => setConfirmDelete(true), []);

  const handleDelete = useCallback(async (confirmed) => {
    setConfirmDelete(false);
    closeMenu();
    if (confirmed && confirmDelete) {
      await jobDelete({variables: {_id: job._id}});
      refetchSalesDocs();
    }
  }, [closeMenu, confirmDelete, job._id, jobDelete, refetchSalesDocs]);

  const handleGetInvoice = () => {
    const invoiceId = job.digest?.invoice?._id;
    dispatch(setInvoiceModalOnClose(refetchSalesDocs));
    if (!invoiceId) {
      dispatch(draftInvoiceFromJob(job._id));
    } else if (user?.company?.accountingPlatform?.platform === 'XERO' && job.digest?.invoice?.balance < job.digest?.invoice?.totalAmount) {
      setXeroPaymentInfoDialogOpen(true);
    } else {
      dispatch(getInvoice(invoiceId));
    }
  };

  return (
    <>
      <PopupMenuList className={'job-action-menu'}>
        {!job.completed && !job.voided &&
          <PopupItem>
            <Link to={`/jobs/${job._id}/edit`}>
              <Icon>edit</Icon>
              <BodyText>Edit</BodyText>
            </Link>
          </PopupItem>
        }
        {!job.voided &&
          <>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <PopupItem prefix='send' text='Send' onClick={handleSend} />
            </PermissionBasedContent>
            <PopupItem>
              <Link href={pdfLink}>
                <Icon>description</Icon>
                <BodyText>PDF Job Sheet</BodyText>
              </Link>
            </PopupItem>
            <PopupItem>
              <Link href={`${pdfLink}?withProofs=1`}>
                <Icon>description</Icon>
                <BodyText>PDF Proof Sheet</BodyText>
              </Link>
            </PopupItem>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <PopupItem>
                <Link href={packingSlipLink}>
                  <Icon>description</Icon>
                  <BodyText>PDF Packing Slip</BodyText>
                </Link>
              </PopupItem>
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <PopupItem prefix='description' text='PDF Decorator Spec Sheet' onClick={handleShowDecoratorSpecSheet} keepOpenHidden={true} />
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
              <PopupItem prefix='file_copy' text='Duplicate' onClick={handleDuplicate} />
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <PopupItem>
                <Link href={shippingLabelLink}>
                  <Icon>local_shipping</Icon>
                  <BodyText>Shipping Label</BodyText>
                </Link>
              </PopupItem>
            </PermissionBasedContent>
            <PopupItem prefix='image' text='Upload Proof' onClick={handleUploadProof} />
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <PopupItem prefix='description' text='Add Purchase Order' keepOpenHidden={true} onClick={handleAddPo} />
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <PopupItem prefix='assignment_turned_in' text='Add Task' onClick={handleAddTask} />
            </PermissionBasedContent>
          </>
        }
        <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
          {archived &&
            <PopupItem prefix='unarchived' text='UnArchive' onClick={() => handleArchived(false)} />
          }
          {!archived &&
            <PopupItem prefix='archived' text='Archive' onClick={() => handleArchived(true)} />
          }
        </PermissionBasedContent>
        <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
          {(job.completed || job.voided) &&
            <>
              {archived &&
                <PopupItem prefix='unarchived' text='UnArchive' onClick={() => handleArchived(false)} />
              }
              {!archived &&
                <PopupItem prefix='archived' text='Archive' onClick={() => handleArchived(true)} />
              }
            </>
          }
        </PermissionBasedContent>
        {!job.voided &&
          <>
            <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
              {!job.completed && (job.digest?.invoice ?? job.invoice)?._id &&
                <PopupItem prefix='attach_money' text='Mark as Complete' onClick={() => handleCompletedMenuItem()} keepOpenHidden />

              }
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
              <PopupItem prefix='delete' text='Delete' onClick={handleShowConfirmDelete} keepOpenHidden />
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <>
                {accountingConnected && !job.completed && !job.voided &&
                  <PopupItem prefix='payment' text={(job.digest?.invoice ?? job.invoice)?._id ? 'Edit Invoice' : 'Create Invoice'} onClick={handleGetInvoice} />
                }
                {accountingConnected && !job.completed && !job.voided && (job.digest?.invoice ?? job.invoice)?._id &&
                  <PopupItem>
                    <Link href={invoicePdfLink}>
                      <Icon>description</Icon>
                      <BodyText>Get Invoice PDF</BodyText>
                    </Link>
                  </PopupItem>
                }
              </>
            </PermissionBasedContent>
          </>
        }
        <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
          {!job.voided &&
            <PopupItem prefix='cancel' text='Void' onClick={() => handleVoidedMenuItem(true)} keepOpenHidden />
          }
        </PermissionBasedContent>
      </PopupMenuList>

      {openAddPo &&
        <AddPrurchaseOrderModal jobId={job._id} open={openAddPo} closeModal={() => setOpenAddPo(false)} />
      }
      {openDecoratorSpecSheetModal &&
        <DecoratorSpecSheetModal jobId={job._id} onClose={handleCloseDecoratorSpecSheet} />
      }
      {openVoidedModal &&
        <VoidJobConfirmation onConfirm={handleVoidedConfirmation} />
      }
      {openJobCompletedModal &&
        <CompleteJobConfirmation onConfirm={handleCompletedConfirmation} />
      }
      {xeroPaymentInfoDialogOpen &&
        <XeroPaymentInfoDialog open={xeroPaymentInfoDialogOpen} onClose={() => setXeroPaymentInfoDialogOpen(false)} />
      }
      {confirmDelete &&
        <DeleteViewConfirmation onClose={handleDelete} number={job.number} />
      }
    </>
  );
};

const DeleteViewConfirmation = ({onClose, number}) => (
  <AttentionConfirmDialog
    onClose={onClose}
    okText={'Delete'}
    cancelText={'Cancel'}
    warningIcon
    mild
  >
    <BodyText>Are you sure you want to delete <b>Job Number {number}</b></BodyText>
  </AttentionConfirmDialog>
);

export const CompleteJobConfirmation = ({onConfirm}) => (
  <AttentionConfirmDialog
    onClose={onConfirm}
    title={'Mark Job as Complete'}
    okText={'Mark as Complete'}
    cancelText={'Cancel'}
    warningIcon
    mild
  >
    <BodyText>
      Are you sure you want to mark this Job as Complete?
      <br /> No further edits can be made to the Job or Invoice.
    </BodyText>
    <BodyText text={'This action cannot be undone.'} />
  </AttentionConfirmDialog>
);

export const VoidJobConfirmation = ({onConfirm}) => (
  <AttentionConfirmDialog
    onClose={onConfirm}
    title={'Void Job'}
    okText={'Void'}
    cancelText={'Cancel'}
    warningIcon
    mild
  >
    <BodyText text={'Are you sure you want to Void this Job?'} />
    <BodyText text={'This action cannot be undone.'} />
  </AttentionConfirmDialog>
);
