import {uniq} from 'lodash';
import {
  asCurrencyRateRounded,
  asCurrencyRounded,
  asNumber,
  asPercentageRounded,
  bind,
  boolValueFromEvent,
  byLocaleCaseInsensitive,
  capitalizeFirst,
  checkedFromEvent,
  listToMap,
  markupFromMargin,
  randomString,
  sortSizes,
  unbind,
  valueFromEvent
} from '../../../utils';
import {
  additionalCostSubstitutions,
  companySubstitutions,
  decorationSubstitutions,
  doTemplateSubstitution,
  nestSubstitutions,
  oneOffDecorationSubstitutions,
  oneOffProductSubstitutions,
  productSubstitutions,
  removeMissingSubstitutions,
  salesDocSubstitutions
} from '../../../models/TemplateSubstitutions';
import {Markups} from '../../../models';
import {PriceCalculator} from './PriceCalculator';
import {SalesDocSummary} from './SalesDocSummary';

export class SalesDoc {
  static Type = {
    CLASSIC_QUOTE: 'classic',
    NEW_QUOTE: 'quoteV2',
    TEMPLATE: 'template',
    PRESENTATION: 'presentation',
    PRESENTATION_TEMPLATE: 'presentationTemplate',
    SALESSTORE: 'salesStore',
    SALESSTORE_TEMPLATE: 'salesStoreTemplate',

    isPresentation: (t) => (t?.documentType ?? t) === SalesDoc.Type.PRESENTATION || (t?.documentType ?? t) === SalesDoc.Type.SALESSTORE,

    getTemplateTypeFromDocumentType(documentType) {
      switch (documentType) {
        case SalesDoc.Type.NEW_QUOTE:
          return SalesDoc.Type.TEMPLATE;

        case SalesDoc.Type.PRESENTATION:
          return SalesDoc.Type.PRESENTATION_TEMPLATE;

        case SalesDoc.Type.SALESSTORE:
          return SalesDoc.Type.SALESSTORE_TEMPLATE;

        default:
          return SalesDoc.Type.TEMPLATE;
      }
    },

    getDocumentTypeFromTemplateType(templateType) {
      switch (templateType) {
        case SalesDoc.Type.TEMPLATE:
          return SalesDoc.Type.NEW_QUOTE;

        case SalesDoc.Type.PRESENTATION_TEMPLATE:
          return SalesDoc.Type.PRESENTATION;

        case SalesDoc.Type.SALESSTORE_TEMPLATE:
          return SalesDoc.Type.SALESSTORE;

        default:
          return SalesDoc.Type.NEW_QUOTE;
      }
    }
  };

  static emptyCommonTemplate = {
    additionalCostDescription: '{{name}}',
    checkoutDiscountEnabled: false,
    checkoutDiscountAmount: 2,
    checkoutSurchargeEnabled: false,
    checkoutSurchargeAmount: 2,
    decorationDescription: '{{name}}',
    productDescription: '{{name}}',
    rollupAdditionalCostSellPrice: true,
    rollupDecorationSellPrice: true,
    rounding: 2,
    showAdditionalCostItems: true,
    showDeadline: false,
    showDecorationItems: true,
    showReference: false,
    showVariants: true,
    tax: null,

    // Customer actions buttons
    showHideDetailsButtonsColor: '#00CCF0',
  };

  static emptySalesDocTemplate = {
    discountPercentage: 5,
    discountDescription: '{{name}} {{percentage}}%',
    discountName: 'Discount',
    discountRollup: false,
    discountShowItems: true,
    hideTotals: false,
    statusAtAcceptFieldName: undefined,
    statusAtAcceptLabel: undefined,
    statusAtCreateFieldName: undefined,
    statusAtCreateLabel: undefined,
    statusAtPaymentFieldName: undefined,
    statusAtPaymentLabel: undefined,
    surchargePercentage: 5,
    surchargeDescription: '{{name}} {{percentage}}%',
    surchargeName: 'Surcharge',
    surchargeRollup: false,
    surchargeShowItems: false,
    variantPriceMode: 'variant',

    // Customer actions buttons
    acceptButtonColor: '#00CCF0',
    acceptButtonText: 'ACCEPT',
    acceptButtonEnabled: true,
    commentButtonColor: '#00CCF0',
    commentButtonText: 'LEAVE COMMENT',
    commentButtonEnabled: true,
    payButtonColor: '#22CC96',
    payButtonText: 'ACCEPT & PAY',
    payButtonEnabled: true,
    pdfButtonColor: '#00CCF0',
    pdfButtonText: 'DOWNLOAD PDF',
    pdfButtonEnabled: true,
  };

  static emptyPresentationTemplate = {
    cartTemplateId: null,
    footerImage: null,
    headerImage: null,
    presentationAdditionalCostPriceMode: 'flatRate',
    presentationDecorationPriceMode: 'quantityBased',
    showFooterBlock: false,
    showHeaderBlock: false,
    showPricing: true,
    showTitleBlock: true,
    titleImage: null,

    // Customer actions buttons
    backButtonColor: '#00CCF0',
    backButtonText: 'BACK',
    cartButtonColor: '#22CC96',
    cartButtonText: 'ADD TO CART',
    cartButtonEnabled: true,
    cartIconColor: '#5E6478',
    checkOutButtonColor: '#22CC96',
    checkOutButtonText: 'CHECK OUT',
    continueShoppingButtonColor: '#00CCF0',
    continueShoppingButtonText: 'CONTINUE SHOPPING',
    likeButtonColor: '#00CCF0',
    likeButtonEnabled: true,
    nextButtonColor: '#22CC96',
    nextButtonText: 'NEXT',
    payLaterButtonColor: '#00CCF0',
    payLaterButtonText: 'PAY LATER',
    payLaterButtonEnabled: true,
    payNowButtonColor: '#22CC96',
    payNowButtonText: 'PAY NOW',
    payNowButtonEnabled: true,
    processPaymentButtonColor: '#22CC96',
    processPaymentButtonText: 'PROCESS PAYMENT & COMPLETE ORDER',
    updateCartButtonColor: '#22CC96',
    updateCartButtonText: 'UPDATE CART',
  };

  static emptyContact = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    deleted: false
  };

  static emptyCustomer = {
    type: 'COMPANY',
    email: '',
    phone: '',
    website: '',
    contacts: [SalesDoc.emptyContact],
    addresses: [],
    settings: {whitelabelProofPortal: false},
    profile: '',
    customerRepId: null
  };

  static emptyShopper = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
  };

  static OnlinePaymentStatus = {
    SUCCESSFUL: 'successful',
    REJECTED: 'rejected',
    PENDING: 'pending',
  };

  newItem;
  newItemId;
  deletedItemIndex;
  _notify;
  summary;
  quantities;
  markups;
  defaultTax;
  priceCalculator;

  _id;
  documentType = SalesDoc.Type.NEW_QUOTE;

  docTypeName;          // Doc type like Quote, Presentation etc
  docTypeDescription;   // Description of the doc type
  docTemplateName;      // Name of the template being used
  docTemplateId;        // _id of the template
  salesStoreId;         // _id of the SalesStore document this was created from
  salesStoreNumber;     // SalesDoc number of sales store
  salesDocCount;        // Count of salesDocs created from this store

  items;
  decorations;
  products;
  vendors;

  createdAt;
  createdBy;
  createdById;
  updatedAt;
  updatedBy;
  updatedById;

  accepted;
  acceptedAt;
  billingAddress;
  company;
  companyTradingEntityId;
  contactId;
  contact;
  customerId;
  customer;
  deadline;
  footerText;
  headerText;
  leadSource;
  number;
  ownerId;
  owner;
  reference;
  shippingAddress;
  shopper;
  status = 'CREATED';
  terms;
  title;
  titleText;
  viewed;

  subTotal;
  taxTotal;
  total;

  invoice;
  onlinePaymentStatus;
  onlinePaymentAmount;
  stripeInvoiceId;
  stripeInvoiceAmount;
  hosting;

  template;

  constructor(that) {
    if (that) {
      Object.assign(this, that);
      this.template = {
        ...SalesDoc.emptyCommonTemplate,
        ...(this.isPresentation() ? SalesDoc.emptyPresentationTemplate : SalesDoc.emptySalesDocTemplate),
        ...that.template,
      };
    }

    this.decorations = this.decorations ?? {};
    this.products = this.products ?? {};
    this.summary = this.summary ?? new SalesDocSummary();
    this.template = this.template ?? {
      ...SalesDoc.emptyCommonTemplate,
      ...(this.isPresentation() ? SalesDoc.emptyPresentationTemplate : SalesDoc.emptySalesDocTemplate),
    };
    this.vendors = this.vendors ?? {};

    if (this.isPresentation() && this.template.pdfButtonEnabled) {
      // The PDF button doesn't work at the moment, but we can't just disable it in the
      // guest view, because we don't want it to suddenly appear on old presentations once the
      // feature is available.
      this.template.pdfButtonEnabled = false;
    }

    if (!this.priceCalculator) {
      this.priceCalculator = new PriceCalculator();
    }

    bind(this);

    if (!this.items || !Array.isArray(this.items)) {
      this.items = [new SalesDocItem({type: SalesDocItem.Type.GROUP_PLACEHOLDER})];
    } else if (this.items.length === 0) {
      this.items.push(new SalesDocItem({type: SalesDocItem.Type.GROUP_PLACEHOLDER}));
    }

    // The items need to have a reference to the doc, so they update the list as needed
    this.items.forEach((item) => item.doc = this);
  }

  // Make a copy of the document, with some changes
  copyWith(that) {
    return new SalesDoc({...this, ...that, template: {...this.template, ...that.template}});
  }

  static createEmpty(user, {type} = {}) {
    return new SalesDoc({
      ...this.emptyUser(user),
      newItem: undefined,
      newItemId: undefined,
      deletedItemIndex: undefined,
      documentType: type ?? SalesDoc.Type.NEW_QUOTE,
    });
  }

  static emptyUser(user) {
    const now = new Date();
    return {
      createdAt: now,
      createdBy: user,
      createdById: user?._id,
      updatedAt: now,
      updatedBy: user,
      updatedById: user?._id,
      owner: user,
      ownerId: user?._id,
    };
  }

  // Will notify and cause a state change
  notify(options = {}) {
    if (this._notify) {
      this._notify(this, options);
    }
    return this;
  }

  setNotify(notify) {
    this._notify = notify;
    return this;
  }

  // Convert the SalesDoc from the back end format to what we use in the front end
  static fromApi(apiSalesDoc, {cleanDuplicate, cleanConvert, priceCalculator} = {}) {
    if (!apiSalesDoc || !apiSalesDoc.number) {
      return apiSalesDoc;
    }

    const vendors = listToMap(apiSalesDoc.vendors);

    const newSalesDoc = new SalesDoc({
      ...apiSalesDoc,
      priceCalculator,
      decorations: {},
      products: {},
      vendors,
      summary: null,
      ...(!cleanDuplicate ? {} : {
        // Clean out fields additional fields for duplicate, most are done by the backend
        _id: null,
        number: null,
      }),
      ...(!cleanConvert || !SalesDoc.Type.isPresentation(apiSalesDoc) ? {} : {
        // Clean out the template when converting a Presentation to a SalesDoc
        documentType: SalesDoc.Type.NEW_QUOTE,
        documentTypeOrigin: apiSalesDoc.documentType,
        docTypeName: undefined,
        docTypeDescription: undefined,
        docTemplateName: undefined,
        docTemplateId: undefined,
      }),
      items: cleanConvert ? [] : apiSalesDoc.items.map((item) => new SalesDocItem({
        ...item,
        additionalCost: asCurrencyRateRounded(item.additionalCost),
        buyPrice: asCurrencyRateRounded(item.buyPrice),
        colors: !SalesDoc.Type.isPresentation(apiSalesDoc) || cleanConvert ? undefined : item.colors,
        image: SalesDoc.Type.isPresentation(apiSalesDoc) && cleanConvert ? item.images?.[0] : item.image,
        images: !SalesDoc.Type.isPresentation(apiSalesDoc) || cleanConvert ? undefined : item.images,
        markup: asPercentageRounded(item.markup),
        percentageDiscount: item.percentage && item.percentage < 0,
        percentageSurcharge: item.percentage && item.percentage > 0,
        quantity: asNumber(item.quantity),
        sellPrice: asCurrencyRateRounded(item.sellPrice),
        sizes: !SalesDoc.Type.isPresentation(apiSalesDoc) || cleanConvert ? undefined : item.sizes,
        subTotal: asCurrencyRounded(item.subTotal),
        totalCost: asCurrencyRateRounded(item.totalCost),
        unitPrice: asCurrencyRateRounded(item.unitPrice),
        vendor: vendors[item.vendorId],
        vendorName: vendors[item.vendorId]?.name,
      }))
    })
      .addVendorsFromApi(apiSalesDoc.vendors)
      .addDecorationsFromApi(apiSalesDoc.decorations)
      .addProductsFromApi(apiSalesDoc.products);

    return newSalesDoc.recalculate();
  }

  // Return the SalesDoc in the format expected by the back end
  forApi() {
    const serialized = this.copyWith({
      documentTypeOrigin: undefined,
      footerText: this.getSubstitutedText('footerText'),
      headerText: this.getSubstitutedText('headerText'),
      titleText: this.getSubstitutedText('titleText'),
      items: this.items
        .map((item) => ({
          ...item,
          description: item.getSubstitutedDescription(),
        }))
    })
      .#serialize();

    Object.assign(serialized, {
      contact: this.contact && {
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        email: this.contact.email,
        phone: this.contact.phone,
      },
      customer: this.customer && {
        name: this.customer.name,
        email: this.customer.email,
        phone: this.customer.phone,
      },
      owner: {
        firstName: this.owner.firstName,
        lastName: this.owner.lastName,
        username: this.owner.username,
      },
      decorations: uniq(this.items.map(({decoration}) => decoration).filter(Boolean)).map(({name, category}) => ({name, category})),
      products: uniq(this.items.map(({product}) => product).filter(Boolean)).map(({title, code, brand}) => ({title, code, brand})),
      vendors: uniq(this.items.map(({vendor}) => vendor).filter(Boolean)).map(({name, email}) => ({name, email})),
      items: serialized.items.filter(({type}) => !type.includes('Placeholder')),
    });

    return serialized;
  }

  // Unpack autoSave salesDoc and format for use in the front end
  static fromAutoSave(salesDoc, user) {
    if (!salesDoc) {
      return salesDoc;
    }

    // Convert the list of products, decorations, and vendors to maps
    const products = listToMap(salesDoc.products);
    const decorations = listToMap(salesDoc.decorations);
    const vendors = listToMap(salesDoc.vendors);

    return SalesDoc.createEmpty(user).copyWith({
      ...salesDoc,
      customer: undefined,
      products: {},
      decorations,
      vendors,
      items: salesDoc.items.map((item) => new SalesDocItem({
        ...item,
        product: products[item.productId],
        decoration: decorations[item.decorationId],
        vendor: vendors[item.vendorId],
        percentageDiscount: item.percentage && item.percentage < 0,
        percentageSurcharge: item.percentage && item.percentage > 0,
      })),
    })
      .addProductsFromApi(salesDoc.products)
      .addCustomerFromApi(salesDoc.customer)
      .recalculate();
  }

  // Pack salesDoc to be stored as autoSave record in local storage
  forAutoSave() {
    const serialized = this.#serialize();

    Object.assign(serialized, {
      contact: this.contact && {
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        email: this.contact.email,
        phone: this.contact.phone,
      },
      customer: this.customer && {
        _id: this.customer._id,
        name: this.customer.name,
        email: this.customer.email,
        phone: this.customer.phone,
      },
      decorations: this.decorations && Object.entries(this.decorations).map(([key, value]) => ({_id: value._id ?? key})),
      products: this.products && Object.entries(this.products).map(([key, value]) => ({_id: value._id ?? key})),
      vendors: this.vendors && Object.entries(this.vendors).map(([key, value]) => ({_id: value._id ?? key})),
    });

    return serialized;
  }

  // Initialize from a template
  static fromTemplate(template, user) {
    return new SalesDoc({
      ...template,
      template: {...template.template},
      _id: null,
      footerText: template.footerText,
      headerText: template.headerText,
      titleText: template.titleText,

      docTemplateId: template._id,
      documentType: SalesDoc.Type.getDocumentTypeFromTemplateType(template.documentType),
    }).copyWith(SalesDoc.emptyUser(user))
      .updateCheckoutDiscountSurcharge(!SalesDoc.Type.isPresentation(template))
      .recalculate();
  }

  switchTemplate(template) {
    return this.copyWith({
      template: {...template.template},
      docTypeName: template.docTypeName,
      docTypeDescription: template.docTypeDescription,
      docTemplateName: template.docTemplateName,
      docTemplateId: template._id,
      footerText: template.footerText,
      headerText: template.headerText,
      titleText: template.titleText,
      terms: template.terms,
    })
      .recalculate()
      .notify();
  }

  // Return the SalesDoc as a template
  forTemplate() {
    const template = new SalesDoc({
      _id: undefined,
      docTemplateId: this.docTemplateId,
      documentType: SalesDoc.Type.getTemplateTypeFromDocumentType(this.documentType),
      documentTypeOrigin: undefined,
      createdAt: undefined,
      createdById: undefined,
      docTypeName: this.docTypeName,
      docTypeDescription: this.docTypeDescription,
      docTemplateName: this.docTemplateName,
      template: this.template,
      footerText: this.footerText,
      headerText: this.headerText,
      titleText: this.titleText,

      terms: this.terms,
    }).#serialize();

    delete template.items;

    return template;
  }

  createCart({template, priceCalculator, withSurchargesDiscounts}) {
    return new SalesDoc({
      ...this,
      priceCalculator,
      summary: null,
      quantities: null,
      template: {
        ...template.template,
        ...SalesDoc.emptyCommonTemplate,
        ...SalesDoc.emptySalesDocTemplate,
        // Remove the presentation template info
        ...Object.keys(SalesDoc.emptyPresentationTemplate).reduce((props, key) => ({...props, [key]: undefined}), {}),
        checkoutDiscountEnabled: this.template.checkoutDiscountEnabled,
        checkoutDiscountAmount: this.template.checkoutDiscountAmount,
        checkoutSurchargeEnabled: this.template.checkoutSurchargeEnabled,
        checkoutSurchargeAmount: this.template.checkoutSurchargeAmount,
      },
      createdAt: undefined,
      createdById: undefined,
      documentType: SalesDoc.Type.NEW_QUOTE,
      hosting: undefined,
      salesStoreId: this.documentType === SalesDoc.Type.SALESSTORE ? this._id : undefined,
      salesStoreNumber: this.documentType === SalesDoc.Type.SALESSTORE ? this.number : undefined,
      salesDocCount: this.documentType === SalesDoc.Type.SALESSTORE ? this.salesDocCount : undefined,
      viewed: 0,
      items: [],
      decorations: {...this.decorations},
      products: {...this.products},
      vendors: {...this.vendors},
    })
      .updateCheckoutDiscountSurcharge(withSurchargesDiscounts)
      .setNotify(null)
      .switchTemplate(template);
  }

  updateCheckoutDiscountSurcharge(addSurchargesDiscounts) {
    if (addSurchargesDiscounts) {
      const items = this.items.filter((item) => !item.applyAtCheckout);
      if ((this.isPresentation() && this.template.cartButtonEnabled) || (!this.isPresentation() && this.template.payButtonEnabled)) {
        if (this.template.checkoutSurchargeEnabled) {
          items.push(new SalesDocItem({
            applyAtCheckout: true,
            description: doTemplateSubstitution(this.template.surchargeDescription, additionalCostSubstitutions, {}),
            name: this.template.surchargeName,
            quantity: 1,
            percentage: this.template.checkoutSurchargeAmount,
            percentageSurcharge: true,
            priceMode: SalesDocItem.PriceMode.FLAT_RATE,
            rollupSellPrice: false,
            showItem: true,
            tax: this.template.tax ?? this.defaultTax,
            type: SalesDocItem.Type.ADDITIONAL_COST,
          }));
        }
        if (this.template.checkoutDiscountEnabled) {
          items.push(new SalesDocItem({
            applyAtCheckout: true,
            description: doTemplateSubstitution(this.template.discountDescription, additionalCostSubstitutions, {}),
            name: this.template.discountName,
            quantity: 1,
            percentage: -this.template.checkoutDiscountAmount,
            percentageDiscount: true,
            priceMode: SalesDocItem.PriceMode.FLAT_RATE,
            rollupSellPrice: false,
            showItem: true,
            tax: this.template.tax ?? this.defaultTax,
            type: SalesDocItem.Type.ADDITIONAL_COST,
          }));
        }
      }
      return this.copyWith({items}).recalculate();
    }
    return this;
  }

  #serialize() {
    return {
      ...unbind(this),
      newItem: undefined,
      newItemId: undefined,
      deletedItemIndex: undefined,
      _notify: undefined,
      summary: undefined,
      priceCalculator: undefined,
      quantities: undefined,
      markups: undefined,
      defaultTax: undefined,
      decorations: undefined,
      products: undefined,
      vendors: undefined,

      customer: undefined,
      contact: undefined,
      owner: undefined,

      createdBy: undefined,
      updatedBy: undefined,

      items: this.items.map((item) => ({
        ...unbind(item),
        variantId: this.isPresentation() || item.isProductVariant() ? item.variantId : undefined,
        // These fields are used by the front end in calculations, but are not part of the model
        averageSellPrice: undefined,
        buyPricePerUnit: undefined,
        doc: undefined,
        decoration: undefined,
        isAveragedPrice: undefined,
        linkedQuantity: undefined,
        minQuantity: undefined,
        percentageDiscount: undefined,
        percentageSurcharge: undefined,
        product: undefined,
        rolledUpCost: undefined,
        rolledUpUnitPrice: undefined,
        unitPriceCalc: undefined,
        vendor: undefined,
        vendorName: undefined,
      })),
    };
  }

  setCompany(company) {
    // Company is not part of the state, so don't notify or recalculate
    this.company = company;

    if (this.isPresentation() && !this.template.titleImage) {
      this.template.titleImage = company.logo ?? company.companyTradingEntities?.[0]?.logo;
    }

    this.defaultTax = company.taxes.find((tax) => tax.type === 'revenue' && tax.isDefault);

    return this;
  }

  setMarkups(markups) {
    // Markups are not part of the state, so don't notify or recalculate
    this.markups = markups;
    return this;
  }

  // Add a customer from the back end, the customer will be ignored if it does not have the correct ID
  addCustomerFromApi(customer) {
    if (customer && this.customer !== customer && this.customerId === customer._id) {
      return this.copyWith({customer, customerId: customer?._id});
    }
    return this;
  }

  // Add a decoration or array of decorations from the back end
  addDecorationsFromApi(apiDecorations) {
    if (!apiDecorations || apiDecorations.length === 0) {
      return this;
    }
    if (!Array.isArray(apiDecorations)) {
      apiDecorations = [apiDecorations];
    }

    const decorations = {};
    const vendors = [];
    apiDecorations.forEach((decoration) => {
      if (decoration.vendor) {
        vendors.push(decoration.vendor);
      }
      decorations[decoration._id] = decoration;
    });

    // Update any items that reference the decoration to use the new decoration
    const items = this.items.map((item) => decorations[item.decorationId] == null ? item : item.copyWith({
      decoration: decorations[item.decorationId],
      productId: decorations[item.decorationId].productId
    }));

    // Create a new document with updated items, products and vendors
    return this.copyWith({
      items,
      decorations: {...this.decorations, ...decorations},
    }).addVendorsFromApi(vendors);
  }

  // Add a product or array of products from the back end
  addProductsFromApi(apiProducts) {
    if (!apiProducts || apiProducts.length === 0) {
      return this;
    }
    if (!Array.isArray(apiProducts)) {
      apiProducts = [apiProducts];
    }

    // Convert each product to the expected format, and track the new products and vendors
    const products = {};
    const vendors = [];
    apiProducts.forEach((product) => {
      const colorsMap = {};
      const sizesMap = {};
      const variantImagesMap = {};

      product.variants?.filter(Boolean).forEach((variant) => {
        if (colorsMap[variant.color] == null) {
          colorsMap[variant.color] = variant.color;
        }
        if (sizesMap[variant.size] == null) {
          sizesMap[variant.size] = variant.size;
        }
        if (variantImagesMap[variant.color] == null && variant.image) {
          variantImagesMap[variant.color] = variant.image;
        }
      });

      // For Sage products get the images and colors
      const namedImagesMap = product.extensions?.pics.reduce((acc, {caption, index, url}) => ({...acc, [caption || index.toString()]: url}), {}) ?? [];
      if (!product.variants?.length) {
        product.extensions?.colors?.split(/\s*,\s*/).forEach((color) => colorsMap[color] = color);
      }

      // Sort the colors alphabetically, then create a new color map that has the keys sorted alphabetically as well
      const colors = Object.keys(colorsMap).toSorted(byLocaleCaseInsensitive);
      const names = uniq([...colors, ...Object.keys(namedImagesMap)]).toSorted(byLocaleCaseInsensitive);
      const namedImages = names.reduce((acc, name) => {
        const image = variantImagesMap[name] || namedImagesMap[name];
        if (image) {
          acc[name] = image;
        }
        return acc;
      }, {});

      if (product.vendor) {
        vendors.push(product.vendor);
      }

      products[product._id] = {
        variants: [],
        ...product,
        colors,
        sizes: sortSizes(Object.keys(sizesMap)),
        namedImages,
        primaryImage: Object.values(namedImages).includes(product.primaryImage) ? null : product.primaryImage
      };
    });

    // Update any items that reference the product to use the new product and possibly vendor
    const items = this.items.map((item) => {
      if (products[item.productId] == null) {
        return item;
      } else {
        const product = products[item.productId];
        const primaryImageList = product.primaryImage ? [product.primaryImage] : [];
        return item.copyWith({
          product,
          image: item.image || (item.type !== SalesDocItem.Type.VARIANT ? null : (product.primaryImage || Object.values(product.namedImages ?? {})[0])),
          ...(!this.isPresentation() ? {} : {
            colors: item.colors ?? (product.colors?.length > 0 ? [...product.colors] : undefined),
            sizes: item.sizes ?? (product.sizes?.length > 0 ? [...product.sizes] : undefined),
            images: item.images ?? (product.namedImages && Object.values(product.namedImages).length > 0
              ? uniq([...primaryImageList, ...Object.values(product.namedImages)])
              : primaryImageList),
          }),
        });
      }
    });

    // Create a new document with updated items, products and vendors
    return this.copyWith({
      items,
      products: {...this.products, ...products},
    })
      .addVendorsFromApi(vendors);
  }

  addVendorsFromApi(vendors) {
    if (!vendors) {
      return this;
    }
    if (!Array.isArray(vendors)) {
      vendors = [vendors];
    }
    vendors = vendors.filter((vendor) => vendor._id && this.vendors[vendor._id]?.name == null);
    if (vendors.length > 0) {
      vendors = listToMap(vendors);
      return this.copyWith({
        vendors: {...this.vendors, ...vendors},
        items: this.items.map((item) => {
          const vendor = vendors[item.vendorId];
          return vendor ? item.copyWith({vendor, vendorName: vendor.name}) : item;
        }),
      });
    }
    return this;
  }

  // Getters

  getAllCategories() {
    const catSet = this.items.reduce((acc, {categories}) => {
      categories?.forEach((category) => { acc[category] = true; });
      return acc;
    }, {});
    return Object.keys(catSet).toSorted(byLocaleCaseInsensitive);
  }

  getFirstItemInGroup(groupId) {
    return this.items.find((item) => item.groupId === groupId);
  }

  getFirstProductVariantItemInGroup(groupId) {
    return this.items.find((item) => item.groupId === groupId && item.isProductVariant());
  }

  getFirstVariantItem(variantId) {
    return this.items.find((item) => variantId != null && item.variantId === variantId);
  }

  getGroupIds() {
    return Object.keys(this.items.reduce((acc, {groupId}) => ({...acc, [groupId]: true}), {}));
  }

  getItem(itemId) {
    return itemId ? this.items.find((item) => item.itemId === itemId) : null;
  }

  getItemsAtCheckout() {
    return this.items.filter((item) => item.applyAtCheckout);
  }

  getItemsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId);
  }

  getLastItemInGroup(groupId) {
    return this.items.findLast((item) => item.groupId === groupId);
  }

  getMainItemInGroup(groupId) {
    return this.getFirstProductVariantItemInGroup(groupId) ?? this.getFirstItemInGroup(groupId);
  }

  getNonProductVariantsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId && item.type !== SalesDocItem.Type.VARIANT && item.type !== SalesDocItem.Type.PRODUCT_PLACEHOLDER);
  }

  getPresentationColumnItem(rowId, quantity) {
    const rowItems = this.getVariantItems(rowId);
    return rowItems.findLast((item) => item.quantity <= quantity) ?? rowItems[0];
  }

  getPresentationRowIdsInGroup(groupId) {
    return Object.keys(
      this.items.filter((item) => item.groupId === groupId && item.variantId != null)
        .reduce((acc, {variantId}) => ({...acc, [variantId]: variantId}), {})
    );
  }

  getProductsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId && item.type === SalesDocItem.Type.VARIANT && item.productId != null).map((item) => item.product);
  }

  getProductVariantsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId && (item.type === SalesDocItem.Type.VARIANT || item.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER));
  }

  getProductVariantIdsInGroup(groupId) {
    return Object.keys(
      this.items.filter((item) => item.groupId === groupId && (item.type === SalesDocItem.Type.VARIANT || item.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER))
        .reduce((acc, {variantId}) => ({...acc, [variantId]: variantId}), {})
    );
  }

  getQuantityByColor(color) {
    return this.getQuantityByField(color, 'color');
  }

  getQuantityByField(value, fieldName) {
    return (this.items.filter((item) => item[fieldName] === value).reduce((acc, {quantity}) => (acc + asNumber(quantity)), 0));
  }
  getQuantityBySize(size) {
    return this.getQuantityByField(size, 'size');
  }

  getRounding() {
    return this.template.rounding ?? 2;
  }

  // Helper for template substitution
  getSubstitutedText(fieldName) {
    const substitutions = nestSubstitutions({salesDoc: salesDocSubstitutions, company: companySubstitutions});
    const fields = {salesDoc: {...this, number: this.number ? this.number : '{{number}}'}, company: this.company};
    return doTemplateSubstitution(this[fieldName], substitutions, fields, true);
  }

  getVariantItems(variantId) {
    return this.items.filter((item) => variantId != null && item.variantId === variantId);
  }

  groupHasProductVariants(groupId) {
    return this.items.some((item) => item.groupId === groupId && item.type === SalesDocItem.Type.VARIANT);
  }

  isPresentation() {
    return this.documentType === SalesDoc.Type.PRESENTATION || this.documentType === SalesDoc.Type.SALESSTORE;
  }

  isPresentationTemplate() {
    return this.documentType === SalesDoc.Type.PRESENTATION_TEMPLATE || this.documentType === SalesDoc.Type.SALESSTORE_TEMPLATE;
  }

  // Setters and adders --- these will update state

  accept() {
    return this.copyWith({
      accepted: true,
      acceptedAt: new Date(),
    }).notify();
  }

  addDecoration(decoration) {
    if (this.decorations[decoration._id] == null) {
      return this.copyWith({decorations: {...this.decorations, [decoration._id]: decoration}})
        .addVendorsFromApi(decoration.vendor)
        .syncPresentationColumns()
        .recalculate()
        .notify();
    }
    return this.recalculate().notify();
  }

  addGroup(afterItem) {
    // Adding a group placeholder does not trigger a change
    if (afterItem) {
      return this.addItemAfter(afterItem, new SalesDocItem({type: SalesDocItem.Type.GROUP_PLACEHOLDER})).notify({noChange: true});
    } else {
      return this.addItem(new SalesDocItem({type: SalesDocItem.Type.GROUP_PLACEHOLDER})).notify({noChange: true});
    }
  }

  addPresentationGroupColumn(groupId, quantity) {
    const rowIds = this.getPresentationRowIdsInGroup(groupId);
    const items = [...this.items];
    rowIds.forEach((rowId) => {
      // Find the item to copy and the index to insert at. Adding a new row is a special case, and we insert
      // after the last item in the row, so find the last item and add 1 to the insert position.
      const refItem = items.find((item) => item.variantId === rowId && item.quantity >= quantity)
        ?? items.findLast((item) => item.variantId === rowId);
      const insertPos = items.indexOf(refItem) + (refItem.quantity >= quantity ? 0 : 1);
      items.splice(insertPos, 0, refItem.copyWith({itemId: undefined, quantity}));
    });
    return this.copyWith({items})
      .recalculate()
      .notify();
  }

  addProduct(product) {
    return this.addProductsFromApi(product)
      .syncPresentationColumns()
      .recalculate()
      .notify();
  }

  addSageProducts(afterItem, products) {
    const newItems = products.map((product) => new SalesDocItem({
      ...SalesDocItem.initVariantProps({
        salesDoc: this,
        type: SalesDocItem.Type.VARIANT,
        groupId: this.isPresentation() || !afterItem ? SalesDoc.newGroupId() : afterItem.groupId,
        catalog: 'sage',
      }),
      ...SalesDocItem.initVariantPropsFromProduct({salesDoc: this, product}),
    }));

    const replaceCount = afterItem.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.items.indexOf(afterItem) + 1 - replaceCount;
    return this.spliceItems(insertPos, replaceCount, ...newItems)
      .addProductsFromApi(products)
      .syncPresentationColumns()
      .recalculate()
      .notify();
  }

  addVendor(vendor) {
    return this.addVendorsFromApi(vendor).notify();
  }

  clearMarkupsOnVariants(variantId) {
    return this.copyWith({items: this.items.map((item) => variantId && item.variantId === variantId && !item.markupOverride ? item.copyWith({markup: null}) : item)});
  }

  copyGroup(groupId) {
    const items = this.getItemsInGroup(groupId);
    const newGroupId = SalesDoc.newGroupId();
    const newVariantIds = items.reduce((acc, item) => ({...acc, [item.variantId]: SalesDoc.newVariantId()}), {});
    const newItemIds = items.reduce((acc, item) => ({...acc, [item.itemId]: SalesDoc.newItemId()}), {});
    items.forEach((item) => {
      // When copying a group, we need to make sure that the correct IDs are assigned to any additional costs
      // associated with decorations
      if (item.isDecorationSetupCost()) {
        const decorationItemId = item.getDecorationItemId();
        newItemIds[item.itemId] = SalesDoc.makeDecorationSetupCostId(newItemIds[decorationItemId]);
      }
    });
    return this.addItemsAfter(items[items.length - 1], items.map((item) => item.copyWith({
      groupId: newGroupId,
      itemId: newItemIds[item.itemId],
      variantId: item.variantId ? newVariantIds[item.variantId] : undefined,
    }))).recalculate().notify();
  }

  copyVariants(variantId) {
    const newVariantId = SalesDoc.newVariantId();
    const items = this.getVariantItems(variantId);
    const newItems = items.map((item) => item.copyWith({
      itemId: undefined,
      variantId: newVariantId,
    }));
    if (items[0]?.isDecoration()) {
      // For decorations, we have to copy the setup cost as well as the decoration itself
      const newSetupCostVariantId = SalesDoc.newVariantId();
      items.forEach((item, index) => {
        const setupCostItem = item.getSetupCostItem();
        if (setupCostItem) {
          newItems.push(setupCostItem.copyWith({
            itemId: SalesDoc.makeDecorationSetupCostId(newItems[index].itemId),
            variantId: newSetupCostVariantId,
          }));
        }
      });
    }
    return this.addItemsAfter(items[items.length - 1], newItems).recalculate().notify();
  }

  deleteGroup(groupId) {
    return this.copyWith({
      items: this.items.filter((item) => item.groupId !== groupId),
      deletedItemIndex: this.items.indexOf(this.getFirstItemInGroup(groupId)),
    }).recalculate().notify();
  }

  deleteGroups(groupIds) {
    return this.copyWith({items: this.items.filter((item) => !groupIds.includes(item.groupId))}).recalculate().notify();
  }

  deletePresentationGroupColumn(groupId, columnIndex) {
    const rowIds = this.getPresentationRowIdsInGroup(groupId);
    const items = [...this.items];
    rowIds.forEach((rowId) => {
      const rowItems = this.getVariantItems(rowId);
      if (columnIndex >= 0 && columnIndex < rowItems.length) {
        items.splice(items.indexOf(rowItems[columnIndex]), 1);
      }
    });
    return this.copyWith({items})
      .recalculate()
      .notify();
  }

  deleteVariants(variantId) {
    // If this is a decoration with a setup cost, delete the setup costs as well
    let setupCostVariantId;
    const firstVariant = this.getFirstVariantItem(variantId);
    if (firstVariant?.isDecoration()) {
      setupCostVariantId = firstVariant.getSetupCostItem()?.variantId;
    }
    return this.copyWith({
      items: this.items.filter((item) => item.variantId !== variantId && (setupCostVariantId == null || item.variantId !== setupCostVariantId)),
      deletedItemIndex: this.items.indexOf(this.getFirstVariantItem(variantId)),
    }).recalculate().notify();
  }

  moveGroup({afterItem, groupId}) {
    const group = this.getItemsInGroup(groupId);
    const itemsWithGroupRemoved = this.items.filter((el) => el.groupId !== groupId);
    return this.copyWith({items: itemsWithGroupRemoved})
      .spliceItems(afterItem ? itemsWithGroupRemoved.indexOf(afterItem) + 1 : 0, 0, ...group)
      .recalculate()
      .notify();
  }

  moveItemToGroup({afterItem, destinationGroupId, itemId}) {
    const movingItem = this.getItem(itemId);
    const itemsWithItemRemoved = this.items.filter((el) => el.itemId !== itemId);
    const docIndex = afterItem ? itemsWithItemRemoved.indexOf(afterItem) + 1 : itemsWithItemRemoved.findIndex((item) => item.groupId === destinationGroupId);
    return this.copyWith({items: itemsWithItemRemoved})
      .spliceItems(docIndex, 0, movingItem.copyWith({groupId: destinationGroupId}))
      .recalculate()
      .notify();
  }

  moveVariantsToGroup({afterItem, destinationGroupId, variantId}) {
    const variants = this.getVariantItems(variantId);
    const itemsWithVariantsRemoved = [...this.items.filter((el) => el.variantId !== variantId)];
    const docIndex = afterItem ? itemsWithVariantsRemoved.indexOf(afterItem) + 1 : itemsWithVariantsRemoved.findIndex((item) => item.groupId === destinationGroupId);
    return this.copyWith({items: itemsWithVariantsRemoved})
      .spliceItems(docIndex, 0, ...variants.map((item) => item.copyWith({groupId: destinationGroupId})))
      .recalculate()
      .notify();
  }

  removeCategory(category) {
    return this.copyWith({
      items: this.items.map((item) => {
        const pos = item.categories?.indexOf(category);
        if (pos >= 0) {
          return item.copyWith({categories: item.categories.toSpliced(pos, 1)});
        } else {
          return item;
        }
      })
    }).notify();
  }

  renameCategory(oldCategory, newCategory) {
    return this.copyWith({
      items: this.items.map((item) => {
        if (item.categories?.includes(oldCategory)) {
          return item.copyWith({categories: item.categories.map((category) => category === oldCategory ? newCategory : category).toSorted(byLocaleCaseInsensitive)});
        } else {
          return item;
        }
      })
    }).notify();
  }

  setShopper(shopper) {
    return this.copyWith({shopper: {...this.emptyShopper, ...shopper}}).notify();
  }

  setBillingAddress(billingAddress) {
    return this.copyWith({billingAddress}).notify();
  }

  setShippingAddress(shippingAddress) {
    return this.copyWith({shippingAddress}).notify();
  }

  setCompanyTradingEntityId(companyTradingEntityId) {
    if (this.companyTradingEntityId !== companyTradingEntityId) {
      const newEntity = this.company.companyTradingEntities.find(({_id}) => _id === companyTradingEntityId);
      if (newEntity) {
        let headerImage = this.template.headerImage;
        const oldEntity = this.company.companyTradingEntities.find(({_id}) => _id === this.companyTradingEntityId) ?? this.company.companyTradingEntities[0];
        if (oldEntity?.logo === headerImage) {
          headerImage = newEntity.logo ?? this.company.companyTradingEntities[0].logo;
        }
        return this.copyWith({
          companyTradingEntityId,
          template: {
            ...this.template,
            headerImage,
          }
        }).notify();
      }
    }
    return this;
  }

  // This actually sets the contact and the customer
  setContact(contact) {
    if (this.contact !== contact || this.contactId !== contact?._id) {
      const customer = contact.customer ?? this.customer;
      let billingAddress = this.billingAddress;
      let shippingAddress = this.shippingAddress;
      if (customer && customer.addresses) {
        billingAddress = customer.addresses.find(({_id}) => _id === billingAddress?._id) ?? customer.addresses.find(({label}) => label === 'BILLING');
        shippingAddress = customer.addresses.find(({_id}) => _id === shippingAddress?._id) ?? customer.addresses.find(({label}) => label === 'SHIPPING');
      }
      return this.copyWith({contact, contactId: contact?._id, customer, customerId: customer?._id, billingAddress, shippingAddress}).notify();
    }
    return this;
  }

  setContactId(...contactId) {
    contactId = valueFromEvent(...contactId);
    if (contactId !== this.contactId && this.customer) {
      const contact = this.customer.contacts?.find(({_id}) => _id === contactId);
      if (contact) {
        return this.copyWith({contact, contactId}).notify();
      }
    }
    return this;
  }

  setDeadline(deadline) {
    if (deadline !== this.deadline || deadline?.getTime() !== this.deadline?.getTime()) {
      return this.copyWith({deadline}).notify();
    }
    return this;
  }

  setDocTemplateName(...docTemplateName) {
    docTemplateName = valueFromEvent(...docTemplateName);
    if (docTemplateName !== this.docTemplateName) {
      return this.copyWith({docTemplateName}).notify();
    }
    return this;
  }

  setDocTemplateId(...docTemplateId) {
    docTemplateId = valueFromEvent(...docTemplateId);
    if (docTemplateId !== this.docTemplateId) {
      return this.copyWith({docTemplateId}).notify();
    }
    return this;
  }

  setDocTypeDescription(...docTypeDescription) {
    docTypeDescription = valueFromEvent(...docTypeDescription);
    if (docTypeDescription !== this.docTypeDescription) {
      return this.copyWith({docTypeDescription}).notify();
    }
    return this;
  }

  setDocTypeName(...docTypeName) {
    docTypeName = valueFromEvent(...docTypeName);
    if (docTypeName !== this.docTypeName) {
      return this.copyWith({docTypeName}).notify();
    }
    return this;
  }

  setFooterBlockText(...value) {
    value = valueFromEvent(...value);
    if (value !== this.template.footerText) {
      return this.copyWith({footerText: value, template: {...this.template, footerText: value}}).notify();
    }
    return this;
  }

  setHeaderBlockText(...value) {
    value = valueFromEvent(...value);
    if (value !== this.template.headerText) {
      return this.copyWith({headerText: value, template: {...this.template, headerText: value}}).notify();
    }
    return this;
  }

  setLeadSource(...value) {
    value = valueFromEvent(...value);
    if (value !== this.leadSource) {
      return this.copyWith({leadSource: value}).notify();
    }
    return this;
  }

  setTitleBlockText(...value) {
    value = valueFromEvent(...value);
    if (value !== this.template.titleText) {
      return this.copyWith({titleText: value, template: {...this.template, titleText: value}}).notify();
    }
    return this;
  }

  setOwner(owner) {
    if (owner && owner._id !== this.ownerId) {
      return this.copyWith({owner, ownerId: owner._id}).notify();
    }
    return this;
  }

  setPresentationGroupColumnQuantity(itemOrGroupId, quantity, columnIndex) {
    if (itemOrGroupId instanceof SalesDocItem) {
      const rowItems = this.getVariantItems(itemOrGroupId.variantId);
      columnIndex = rowItems.indexOf(itemOrGroupId);
      itemOrGroupId = itemOrGroupId.groupId;
    }
    const rowIds = this.getPresentationRowIdsInGroup(itemOrGroupId);
    const items = [...this.items];
    rowIds.forEach((rowId) => {
      const rowItems = this.getVariantItems(rowId);
      if (columnIndex >= 0 && columnIndex < rowItems.length) {
        const item = rowItems[columnIndex];
        items[items.indexOf(item)] = item.copyWith({
          quantity,
          markup: item.markupOverride ? item.markup : null,
        });
      }
    });
    return this.copyWith({items})
      .recalculate()
      .notify();
  }

  setReference(...reference) {
    reference = valueFromEvent(...reference);
    if (reference !== this.reference) {
      return this.copyWith({reference}).notify();
    }
    return this;
  }

  setRounding(...value) {
    return this.#updateTemplate('rounding', ...value).recalculate().notify();
  }

  setTerms(...terms) {
    terms = valueFromEvent(...terms);
    if (terms !== this.terms) {
      return this.copyWith({terms}).notify();
    }
    return this;
  }

  setTemplateAdditionalCostDescription(...value) {
    return this.#updateTemplate('additionalCostDescription', ...value);
  }

  setTemplateCartTemplateId(...value) {
    return this.#updateTemplate('cartTemplateId', ...value);
  }

  setTemplateCheckoutDiscountAmount(...value) {
    value = valueFromEvent(...value);
    return this.copyWith({
      template: {
        ...this.template,
        checkoutDiscountAmount: value,
        checkoutDiscountEnabled: value ? true : this.template.checkoutDiscountEnabled,
      }
    })
      .updateCheckoutDiscountSurcharge(!this.isPresentation())
      .notify();
  }

  setTemplateCheckoutDiscountEnabled(...value) {
    return this.#updateTemplate('checkoutDiscountEnabled', ...value);
  }

  setTemplateCheckoutSurchargeAmount(...value) {
    value = valueFromEvent(...value);
    return this.copyWith({
      template: {
        ...this.template,
        checkoutSurchargeAmount: value,
        checkoutSurchargeEnabled: value ? true : this.template.checkoutSurchargeEnabled,
      }
    })
      .updateCheckoutDiscountSurcharge(!this.isPresentation())
      .notify();
  }

  setTemplateCheckoutSurchargeEnabled(...value) {
    return this.#updateTemplate('checkoutSurchargeEnabled', ...value);
  }

  setTemplateDecorationDescription(...value) {
    return this.#updateTemplate('decorationDescription', ...value);
  }

  setTemplateDiscountAmount(...value) {
    return this.#updateTemplate('discountAmount', ...value);
  }

  setTemplateDiscountDescription(...value) {
    return this.#updateTemplate('discountDescription', ...value);
  }

  setTemplateDiscountRollup(...value) {
    return this.#updateTemplate('discountRollup', checkedFromEvent(...value));
  }

  setTemplateDiscountShowItems(...value) {
    return this.#updateTemplate('discountShowItems', checkedFromEvent(...value));
  }

  setTemplateFooterBlockImage(...value) {
    return this.#updateTemplate('footerImage', ...value);
  }

  setTemplateHeaderBlockImage(...value) {
    return this.#updateTemplate('headerImage', ...value);
  }

  setTemplateHideTotals(...value) {
    return this.#updateTemplate('hideTotals', ...value);
  }

  setTemplatePresentationDecorationPriceMode(...value) {
    return this.#updateTemplate('presentationDecorationPriceMode', valueFromEvent(...value));
  }

  setTemplatePresentationAdditionalCostPriceMode(...value) {
    return this.#updateTemplate('presentationAdditionalCostPriceMode', valueFromEvent(...value));
  }

  setTemplateProductDescription(...value) {
    return this.#updateTemplate('productDescription', ...value);
  }

  setTemplateRollupAdditionalCostSellPrice(...value) {
    return this.#updateTemplate('rollupAdditionalCostSellPrice', boolValueFromEvent(...value));
  }

  setTemplateRollupDecorationSellPrice(...value) {
    return this.#updateTemplate('rollupDecorationSellPrice', boolValueFromEvent(...value));
  }

  setTemplateShowAdditionalCostItems(...value) {
    return this.#updateTemplate('showAdditionalCostItems', ...value);
  }

  setTemplateShowDeadline(...value) {
    return this.#updateTemplate('showDeadline', ...value);
  }

  setTemplateShowDecorationItems(...value) {
    return this.#updateTemplate('showDecorationItems', ...value);
  }

  setTemplateShowPricing(...value) {
    return this.#updateTemplate('showPricing', ...value);
  }

  setTemplateShowReference(...value) {
    return this.#updateTemplate('showReference', ...value);
  }

  setTemplateShowFooterBlock(...value) {
    return this.#updateTemplate('showFooterBlock', ...value);
  }

  setTemplateShowHeaderBlock(...value) {
    return this.#updateTemplate('showHeaderBlock', ...value);
  }

  setTemplateShowTitleBlock(...value) {
    return this.#updateTemplate('showTitleBlock', ...value);
  }

  setTemplateShowVariants(...value) {
    return this.#updateTemplate('showVariants', ...value);
  }

  setTemplateStatusAtAcceptFieldName(...value) {
    return this.#updateTemplate({statusAtAcceptFieldName: valueFromEvent(...value), statusAtAcceptLabel: undefined});
  }

  setTemplateStatusAtAcceptLabel(...value) {
    return this.#updateTemplate('statusAtAcceptLabel', ...value);
  }

  setTemplateStatusAtCreateFieldName(...value) {
    return this.#updateTemplate({statusAtCreateFieldName: valueFromEvent(...value), statusAtCreateLabel: undefined});
  }

  setTemplateStatusAtCreateLabel(...value) {
    return this.#updateTemplate('statusAtCreateLabel', ...value);
  }

  setTemplateStatusAtPaymentFieldName(...value) {
    return this.#updateTemplate({statusAtPaymentFieldName: valueFromEvent(...value), statusAtPaymentLabel: undefined});
  }

  setTemplateStatusAtPaymentLabel(...value) {
    return this.#updateTemplate('statusAtPaymentLabel', ...value);
  }

  setTemplateSurchargeAmount(...value) {
    return this.#updateTemplate('surchargeAmount', ...value);
  }

  setTemplateSurchargeDescription(...value) {
    return this.#updateTemplate('surchargeDescription', ...value);
  }

  setTemplateSurchargeRollup(...value) {
    return this.#updateTemplate('surchargeRollup', checkedFromEvent(...value));
  }

  setTemplateSurchargeShowItems(...value) {
    return this.#updateTemplate('surchargeShowItems', checkedFromEvent(...value));
  }

  setTemplateTax(...value) {
    return this.#updateTemplate('tax', ...value);
  }

  setTemplateTitleBlockImage(...value) {
    return this.#updateTemplate('titleImage', ...value);
  }

  setTemplateButtonProperty(buttonName, fieldName, fieldValue) {
    return this.#updateTemplate(`${buttonName}${capitalizeFirst(fieldName)}`, fieldValue);
  }

  setTemplateVariantPriceMode(...value) {
    return this.#updateTemplate('variantPriceMode', ...value);
  }

  #updateTemplate(field, ...value) {
    const updateCheckout = !this.isPresentation() && [
      'checkoutDiscountEnabled',
      'checkoutSurchargeEnabled',
      'payButtonEnabled',
    ].includes(field);

    if (typeof field === 'string') {
      value = valueFromEvent(...value);
      if (value !== this.template[field]) {
        return this.copyWith({template: {...this.template, [field]: value}}).updateCheckoutDiscountSurcharge(updateCheckout).notify();
      }
    } else {
      return this.copyWith({template: {...this.template, ...field}}).updateCheckoutDiscountSurcharge(updateCheckout).notify();
    }
    return this;
  }

  // Helper functions for adding / replacing / updating items -- these are private to SalesDoc and SalesDocItem

  spliceItems(index, deleteCount, ...newItems) {
    // The order of operations is important here, we want to pass the full array
    // to the constructor so that it will set the correct doc in the items
    const items = [...this.items];
    items.splice(index, deleteCount, ...newItems.map((item) => item.copyWith({tax: item.tax ?? this.template.tax ?? this.defaultTax})));
    return this.copyWith({
      items,
      newItemId: newItems?.[0]?.itemId,
      newItem: newItems?.[0],
      deletedItemIndex: deleteCount > 0 && !newItems?.length ? index : -1,
    });
  }

  addItem(item) {
    return this.spliceItems(this.items.length, 0, item);
  }

  addItemAfter(afterItem, item) {
    return this.spliceItems(afterItem ? this.items.indexOf(afterItem) + 1 : this.items.length, 0, item);
  }

  addItems(items) {
    return this.spliceItems(this.items.length, 0, ...items);
  }

  addItemsAfter(afterItem, items) {
    return this.spliceItems(afterItem ? this.items.indexOf(afterItem) + 1 : this.items.length, 0, ...items);
  }

  addItemsBefore(beforeItem, items) {
    const pos = this.items.indexOf(beforeItem);
    return this.spliceItems(pos >= 0 ? pos : this.items.length, 0, ...items);
  }

  replaceItem(replaceItem, replaceWith) {
    return this.spliceItems(this.items.indexOf(replaceItem), 1, replaceWith);
  }

  deleteItem(deleteItem) {
    const index = this.items.indexOf(deleteItem);
    return index >= 0 ? this.spliceItems(index, 1) : this;
  }

  updateItems(items, updateWith) {
    if (!Array.isArray(items)) {
      items = [items];
    }

    let doc = this;
    items.forEach((item) => {
      doc = doc.replaceItem(item, item.copyWith(updateWith));
    });

    return doc;
  }

  // Recalculates all the prices, quantities etc. The recalculation does everything.
  recalculate() {
    const {items, summary} = this.priceCalculator.calculate(this);
    return this.copyWith({
      items,
      summary,
      ...(this.isPresentation() ? {} : {
        subTotal: summary.subTotal,
        taxTotal: summary.taxTotal,
        total: summary.total
      })
    });
  }

  syncPresentationColumns() {
    if (!this.isPresentation()) {
      return this;
    }

    let items = this.items;

    // Go through each group, initialize any new rows
    this.getGroupIds().forEach((groupId) => {
      let quantities;

      const rowIds = this.getPresentationRowIdsInGroup(groupId);
      rowIds.forEach((rowId) => {
        const rowItems = this.getVariantItems(rowId);
        if (rowItems.length === 1 && (rowItems[0].quantity === 0 || rowItems[0].isAdditionalCost())) {
          if (quantities == null) {
            if (rowIds.length > 1) {
              // There is already a populated row, grab the quantities from any populate row
              const existingRowId = this.items.find((item) => item.groupId === groupId && item.quantity > 0)?.variantId;
              quantities = existingRowId && items
                .filter((item) => item.variantId === existingRowId)
                .map((item) => item.quantity);
            } else if (rowItems[0].type === SalesDocItem.Type.VARIANT && rowItems[0].product == null) {
              quantities = [50, 100, 500];
            } else if (rowItems[0].type === SalesDocItem.Type.VARIANT
              && (rowItems[0].product?.variants?.length || rowItems[0].product?.defaultPriceBreaks?.length)) {
              // This is a whole new group, with a product, grab the quantities from the price breaks
              const allPriceBreaks = {};
              rowItems[0].product.defaultPriceBreaks?.forEach((pb) => allPriceBreaks[pb.quantity] = asNumber(pb.quantity));
              rowItems[0].product.variants?.forEach((pv) => {
                if (pv.priceBreaks?.length > 0) {
                  pv.priceBreaks.forEach((pb) => allPriceBreaks[pb.quantity] = asNumber(pb.quantity));
                }
              });
              quantities = [...Object.values(allPriceBreaks)];
              if (quantities[0] === 0) {
                quantities.splice(0, 1);
              }
              if (quantities && quantities.length <= 1 && (quantities[0] === 0 || quantities[0] == null)) {
                // If there is a single price break, or no price breaks, prepopulate with some useful defaults
                quantities = [50, 100, 500];
              }
            } else if (rowItems[0].type === SalesDocItem.Type.DECORATION && rowItems[0].decoration?.priceBreaks > 0) {
              quantities = rowItems[0].decoration.priceBreaks
                .map((pb) => pb.quantity)
                .filter((quantity) => quantity > 0);
              if (quantities && quantities.length <= 1 && (quantities[0] === 0 || quantities[0] == null)) {
                // If there is a single price break, or no price breaks, prepopulate with some useful defaults
                quantities = [50, 100, 500];
              }
            }
          }
          if (quantities && quantities.length > 0) {
            const item = rowItems[0];
            items = items.toSpliced(items.indexOf(item), 1, ...quantities.map((quantity, index) => item.copyWith({
              itemId: index === 0 ? item.itemId : undefined,
              quantity
            })));
          }
        }
      });
    });

    if (items !== this.items) {
      return this.copyWith({items});
    } else {
      return this;
    }
  }

  static makeDecorationSetupCostId(decorationItemId) {
    return `${decorationItemId}:setup`;
  }

  static makeDecorationId(setupCostItemId) {
    if (setupCostItemId.endsWith(':setup')) {
      return setupCostItemId.substring(0, setupCostItemId.length - 6);
    }
    return null;
  }

  static newItemId() {
    return `item:${randomString()}`;
  }

  static newGroupId() {
    return `group:${randomString()}`;
  }

  static newVariantId() {
    return `v:${randomString()}`;
  }
}

export class SalesDocItem {
  static Type = {
    VARIANT: 'variant',
    DECORATION: 'decoration',
    ADDITIONAL_COST: 'additionalCost',

    // Placeholder item types
    GROUP_PLACEHOLDER: 'groupPlaceholder',
    PRODUCT_PLACEHOLDER: 'productPlaceholder',
    DECORATION_PLACEHOLDER: 'decorationPlaceholder',
  };

  static SHIPPING_DESCRIPTION = 'Shipping  ';

  static MarkupTypes = {
    'variant': Markups.MarkupTypes.product,
    'decoration': Markups.MarkupTypes.decoration,
    'additionalCost': Markups.MarkupTypes.additionalCost,
  };

  static LinkedTo = {
    NONE: 'none',
    GROUP: 'group',
    ALL: 'all',
  };

  static PriceMode = {
    VARIANT: 'variant',
    AVERAGE: 'average',
    FLAT_RATE: 'flatRate',
    QUANTITY_BASED: 'quantityBased',
  };

  doc;

  type;
  itemId;
  groupId;
  variantId;

  additionalCost = 0;
  applyAtCheckout;
  averageSellPrice;
  buyPrice = 0;
  buyPricePerUnit;
  buyPriceOverride = false;
  catalog;
  categories;
  code;
  color = null;
  colors;
  decoration;
  decorationId;
  description = null;
  image;
  images;
  isAveragedPrice;
  liked;
  linkedTo;
  linkedQuantity;
  markup = 0;
  markupOverride = false;
  minQuantity;
  name;
  position = null;
  priceBreaks;
  priceMode;
  product;
  productId;
  percentage;
  percentageDiscount;
  percentageSurcharge;
  quantity = 0;
  rolledUpCost;
  rolledUpUnitPrice;
  rollupSellPrice = false;
  sellPrice = 0;
  showItem;
  showPricing;
  size = null;
  sizes;
  subTotal;
  tax;
  totalCost;
  unitPrice = 0;
  unitPriceCalc;
  unitPriceOverride = false;
  vendor;
  vendorId;
  vendorName;

  constructor(that) {
    // For the most part, when we are copying another item we want to copy the IDs as well, simply
    // because we do so much copying to maintain immutability, but on occasion we need a new ID, in which
    // case, the object being copied must not have an ID.
    if (that) {
      Object.assign(this, that);
    }
    if (this.type === SalesDocItem.Type.VARIANT && this.linkedTo == null) {
      this.linkedTo = SalesDocItem.LinkedTo.ALL;
    }
    this.itemId = this.itemId ?? SalesDoc.newItemId();
    this.groupId = this.groupId ?? SalesDoc.newGroupId();

    bind(this);
  }

  getAdditionalCostQuantity() {
    if (!this.rollupSellPrice || this.doc.isPresentation()) {
      return this.quantity;
    }

    const decorationItem = this.getDecorationItem();
    if (decorationItem) {
      return decorationItem.quantity;
    }

    if (this.productId) {
      return this.doc.summary.quantities[this.groupId][this.productId] ?? 0;
    }

    return this.doc.summary.getGroupQuantity(this.groupId);
  }

  getDecorationItemId() {
    if (this.isAdditionalCost()) {
      return SalesDoc.makeDecorationId(this.itemId);
    }
    return null;
  }

  getDecorationItem() {
    return this.doc.getItem(this.getDecorationItemId());
  }

  getLinkedQuantity() {
    switch (this.linkedTo) {
      case SalesDocItem.LinkedTo.ALL:
        return this.productId ? this.doc.summary.quantities[this.productId] : this.doc.summary.quantities[this.variantId];

      case SalesDocItem.LinkedTo.GROUP:
        return this.productId ? this.doc.summary.quantities[this.groupId][this.productId] : this.doc.summary.quantities[this.groupId][this.variantId];
    }
    return this.quantity;
  }

  getSetupCost() {
    if (this.isDecoration()) {
      if (this.decoration) {
        return this.decoration.setupPrice;
      } else {
        return this.getSetupCostItem()?.buyPrice ?? 0;
      }
    }
    return 0;
  }

  getSetupCostItem() {
    return this.type === SalesDocItem.Type.DECORATION ? this.doc.getItem(SalesDoc.makeDecorationSetupCostId(this.itemId)) : null;
  }

  // Helper for template substitution
  getSubstitutedDescription() {
    switch (this.type) {
      case SalesDocItem.Type.VARIANT:
        return doTemplateSubstitution(this.description, productSubstitutions, {title: this.product?.title ?? this.name, ...this, ...this.product}, true);

      case SalesDocItem.Type.DECORATION:
        return doTemplateSubstitution(this.description, decorationSubstitutions, {...this, ...this.decoration}, true);

      case SalesDocItem.Type.ADDITIONAL_COST:
        return doTemplateSubstitution(this.description, additionalCostSubstitutions, this, true);
    }

    return this.description;
  }

  hasSetupCostItem() {
    return this.getSetupCostItem() != null;
  }

  isAdditionalCost() {
    return this.type === SalesDocItem.Type.ADDITIONAL_COST;
  }

  isDecoration() {
    return this.type === SalesDocItem.Type.DECORATION || this.type === SalesDocItem.Type.DECORATION_PLACEHOLDER;
  }

  isDecorationSetupCost() {
    return this.getDecorationItemId() != null;
  }

  isPlaceholder() {
    switch (this.type) {
      case SalesDocItem.Type.DECORATION_PLACEHOLDER:
      case SalesDocItem.Type.GROUP_PLACEHOLDER:
      case SalesDocItem.Type.PRODUCT_PLACEHOLDER:
        return true;

      default:
        return false;
    }
  }

  // True for decorations and additional costs in presentations that have a flat rate price
  isPresentationAddOnFlatRate() {
    return this.doc.isPresentation()
      && (this.isDecoration() || this.isAdditionalCost())
      && this.priceMode === SalesDocItem.PriceMode.FLAT_RATE
      && this.variantId != null;
  }

  isProductVariant() {
    return this.type === SalesDocItem.Type.VARIANT || this.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER;
  }

  isSurchargeOrDiscount() {
    return this.isAdditionalCost() && !!this.percentage;
  }

  // Setters and update functions  --- these will update state

  addAdditionalCost(props = {}) {
    // If this is a group placeholder, replace it with an additional cost
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    return this.doc.spliceItems(insertPos, replaceCount, new SalesDocItem({
      description: doTemplateSubstitution(props.description ?? this.doc.template.additionalCostDescription, additionalCostSubstitutions, {}),
      groupId: this.groupId,
      quantity: 1,
      priceMode: this.doc.template.presentationAdditionalCostPriceMode,
      rollupSellPrice: this.doc.template.rollupAdditionalCostSellPrice,
      showItem: this.doc.template.showAdditionalCostItems,
      tax: this.doc.template.tax ?? this.doc.defaultTax,
      type: SalesDocItem.Type.ADDITIONAL_COST,
      variantId: this.doc.isPresentation() ? SalesDoc.newVariantId() : undefined,
      ...props,
      buyPrice: props.buyPrice && asCurrencyRateRounded(props.buyPrice),
      ...(props.vendorId && {
        vendor: this.doc.vendors[props.vendorId],
        vendorName: this.doc.vendors[props.vendorId]?.name ?? '',
      }),
    })).syncPresentationColumns().recalculate().notify();
  }

  addAdditionalCostDiscount() {
    return this.addAdditionalCost({
      applyAtCheckout: false,
      description: this.doc.template.discountDescription,
      name: this.doc.template.discountName,
      percentage: -this.doc.template.discountPercentage,
      percentageDiscount: true,
      rollupSellPrice: this.doc.template.discountRollup,
      showItem: this.doc.template.discountShowItems,
    });
  }

  addAdditionalCostSurcharge() {
    return this.addAdditionalCost({
      applyAtCheckout: false,
      description: this.doc.template.surchargeDescription,
      name: this.doc.template.surchargeName,
      percentage: this.doc.template.surchargePercentage,
      percentageSurcharge: true,
      rollupSellPrice: this.doc.template.surchargeRollup,
      showItem: this.doc.template.surchargeShowItems,
    });
  }

  addDecoration() {
    // If this is a group placeholder, replace it with a decoration placeholder
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    return this.doc.spliceItems(
      insertPos,
      replaceCount,
      new SalesDocItem({
        groupId: this.groupId,
        type: SalesDocItem.Type.DECORATION_PLACEHOLDER,
        variantId: this.doc.isPresentation() ? SalesDoc.newVariantId() : undefined,
        tax: this.doc.template.tax ?? this.doc.defaultTax,
        rollupSellPrice: this.doc.template.rollupDecorationSellPrice,
        priceMode: this.doc.template.presentationDecorationPriceMode,
        showItem: this.doc.template.showDecorationItems,
      })
    ).recalculate().notify();
  }

  addOneOffDecoration({name, productId, vendorId, priceMode, priceBreaks, buyPrice, setupCost} = {}) {
    // If this is a group placeholder, replace it with a decoration placeholder
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    const doc = this.doc.spliceItems(insertPos, replaceCount, new SalesDocItem({
      groupId: this.groupId,
      type: SalesDocItem.Type.DECORATION,
      variantId: this.doc.isPresentation() ? SalesDoc.newVariantId() : undefined,
      decoration: null,
      decorationId: null,
      productId: productId ?? null,
      name: name ?? '',
      description: removeMissingSubstitutions(this.doc.template.decorationDescription, oneOffDecorationSubstitutions),
      vendor: this.doc.vendors[vendorId] ?? null,
      vendorId: vendorId ?? null,
      vendorName: this.doc.vendors[vendorId]?.name ?? '',
      tax: this.doc.template.tax ?? this.doc.defaultTax,
      rollupSellPrice: this.doc.template.rollupDecorationSellPrice,
      priceMode: priceMode ?? this.doc.template.presentationDecorationPriceMode,
      buyPrice: asCurrencyRateRounded(buyPrice),
      priceBreaks,
      showItem: this.doc.template.showDecorationItems,
    }));

    if (setupCost) {
      return doc.getItem(doc.newItemId).addSetupCostItem(setupCost);
    }

    return doc.syncPresentationColumns().recalculate().notify();
  }

  addOneOffProduct() {
    // If this is a group placeholder, replace it with a product placeholder
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    return this.doc.spliceItems(
      insertPos,
      replaceCount,
      new SalesDocItem({
        groupId: this.groupId,
        variantId: SalesDoc.newVariantId(),
        type: SalesDocItem.Type.VARIANT,
        linkedTo: this.doc.isPresentation() ? SalesDocItem.LinkedTo.NONE : null,
        productId: null,
        product: null,
        vendorId: null,
        vendor: null,
        vendorName: '',
        description: removeMissingSubstitutions(this.doc.template.productDescription, oneOffProductSubstitutions),
        tax: this.doc.template.tax ?? this.doc.defaultTax,
        priceMode: this.doc.template.variantPriceMode,
        showItem: this.doc.template.showVariants,
        showPricing: this.doc.template.showPricing,
      }),
    ).syncPresentationColumns().recalculate().notify();
  }

  addProduct({catalog} = {}) {
    // If this is a group placeholder, replace it with a product placeholder
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    return this.doc.spliceItems(
      insertPos,
      replaceCount,
      new SalesDocItem(SalesDocItem.initVariantProps({salesDoc: this.doc, groupId: this.groupId, catalog})),
    ).recalculate().notify();
  }

  addProductVariant() {
    return this.doc.addItemAfter(this, new SalesDocItem({
      groupId: this.groupId,
      variantId: this.variantId,
      type: this.type,
      linkedTo: this.linkedTo,
      tax: this.tax,
      priceMode: this.priceMode,
      showItem: this.showItem,
      showPricing: this.showPricing,
      product: this.product,
      productId: this.productId,
      code: this.code,
      description: this.description,
      image: this.image,
      name: this.name,
      quantity: 0,
      vendor: this.vendor,
      vendorId: this.vendorId,
      vendorName: this.vendorName,
    }))
      .recalculate()
      .notify();
  }

  addSetupCostItem(...setupPrice) {
    if (this.type === SalesDocItem.Type.DECORATION) {
      setupPrice = asCurrencyRateRounded(this.decoration?.setupPrice ?? valueFromEvent(...setupPrice));
      const setupCostItem = this.getSetupCostItem();
      if (setupCostItem) {
        return setupCostItem.setBuyPrice(setupPrice);
      } else {
        const updateItems = this.variantId != null ? this.doc.getVariantItems(this.variantId) : [this];
        const isPresentation = this.doc.isPresentation();
        const newVariantId = isPresentation ? SalesDoc.newVariantId() : undefined;
        const newItems = [...this.doc.items];
        updateItems.forEach((updateItem) => {
          const index = newItems.indexOf(updateItem);
          newItems.splice(index + 1, 0, new SalesDocItem({
            type: SalesDocItem.Type.ADDITIONAL_COST,
            variantId: newVariantId,
            itemId: SalesDoc.makeDecorationSetupCostId(updateItem.itemId),
            groupId: updateItem.groupId,
            decorationId: updateItem.decorationId,
            productId: updateItem.productId,
            vendorId: updateItem.vendorId,
            vendor: updateItem.vendor,
            vendorName: updateItem.vendorName,
            name: `${updateItem.name} - Setup`,
            description: `${updateItem.name} - Setup`,
            buyPrice: asCurrencyRateRounded(setupPrice),
            buyPriceOverride: false,
            additionalCost: 0,
            markup: null,
            quantity: isPresentation ? updateItem.quantity : 1,
            tax: updateItem.tax,
            rollupSellPrice: updateItem.rollupSellPrice,
            priceMode: isPresentation ? updateItem.doc.template.presentationAdditionalCostPriceMode : undefined,
            showItem: updateItem.showItem,
          }));
        });
        return this.doc.copyWith({items: newItems}).syncPresentationColumns().recalculate().notify();
      }
    }
    return this.doc;
  }

  copy() {
    let addAfter = this;
    const newItems = [this.copyWith({itemId: undefined})];
    if (this.isDecoration()) {
      const setupCostItem = this.getSetupCostItem();
      if (setupCostItem) {
        addAfter = setupCostItem;
        newItems.push(setupCostItem.copyWith({itemId: SalesDoc.makeDecorationSetupCostId(newItems[0].itemId)}));
      }
    }
    return this.doc.addItemsAfter(addAfter, newItems).clearMarkupsOnVariants(this.variantId).recalculate().notify();
  }

  copyWith(that) {
    return new SalesDocItem({...this, ...that});
  }

  delete() {
    const setupCostItem = this.getSetupCostItem();
    return this.doc.deleteItem(this).deleteItem(setupCostItem).clearMarkupsOnVariants(this.variantId).syncPresentationColumns().recalculate().notify();
  }

  deleteSetupCostItem() {
    if (this.type === SalesDocItem.Type.DECORATION) {
      const setupCostItem = this.getSetupCostItem();
      if (setupCostItem) {
        if (this.doc.isPresentation() && setupCostItem.variantId) {
          return this.doc.deleteVariants(setupCostItem.variantId);
        } else {
          return setupCostItem.delete();
        }
      }
    }
    return this.doc;
  }

  clearBuyPriceOverride() {
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {buyPrice: null, buyPriceOverride: false}).recalculate().notify();
  }

  clearMarkupOverride() {
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {markup: null, markupOverride: false}).recalculate().notify();
  }

  clearUnitPriceOverride() {
    return this.setUnitPrice(null);
  }

  setAdditionalCost(...value) {
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {additionalCost: valueFromEvent(...value)}).recalculate().notify();
  }

  setApplyAtCheckout(...value) {
    const applyAtCheckout = checkedFromEvent(...value);
    return this.copyWith({
      applyAtCheckout,
      ...(applyAtCheckout ? {rollupSellPrice: false} : {}),
    }).#replace(this).recalculate().notify();
  }

  setBuyPrice(...value) {
    value = valueFromEvent(...value);
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {
      buyPrice: value,
      buyPriceOverride: value != null && (this.type !== SalesDocItem.Type.ADDITIONAL_COST || this.decoration != null || this.productId != null),
      markup: this.markupOverride ? this.markup : null,
    }).recalculate().notify();
  }

  setCategories(categories) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {categories: (categories ?? []).toSorted(byLocaleCaseInsensitive)}).notify();
  }

  setCode(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {code: valueFromEvent(...value)}).notify();
  }

  setColor(...value) {
    return this.copyWith({color: valueFromEvent(...value)}).#replace(this).recalculate().notify();
  }

  setColors(colors) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {colors: (colors ?? []).toSorted(byLocaleCaseInsensitive)}).recalculate().notify();
  }

  setDecoration(decoration) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    if (this.doc.decorations[decoration._id]) {
      decoration = this.doc.decorations[decoration._id];
    }
    let doc = this.doc.updateItems(items, {
      groupId: this.groupId,
      type: SalesDocItem.Type.DECORATION,
      decoration: decoration,
      decorationId: decoration._id,
      productId: decoration.productId,
      name: decoration.name,
      description: doTemplateSubstitution(this.doc.template.decorationDescription, decorationSubstitutions, decoration),
      vendor: decoration.vendor,
      vendorId: decoration.vendorId,
      vendorName: decoration.vendorName,
    })
      .syncPresentationColumns()
      .addDecoration(decoration); // This will recalc and notify

    if (decoration.setupPrice > 0) {
      doc = doc.getItem(this.itemId).addSetupCostItem(decoration.setupPrice); // This will also recalc and notify
    } else {
      doc = doc.getItem(this.itemId).deleteSetupCostItem(); // This will also recalc and notify
    }

    return doc;
  }

  setDescription(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {description: valueFromEvent(...value)}).recalculate().notify();
  }

  setImage(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {image: valueFromEvent(...value)}).recalculate().notify();
  }

  setImages(images) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {images}).recalculate().notify();
  }

  setMarkup(...value) {
    value = valueFromEvent(...value);
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {
      markup: value,
      markupOverride: value != null && value !== '',
    })
      .recalculate()
      .notify();
  }

  setMargin(...value) {
    return this.setMarkup(markupFromMargin(valueFromEvent(...value)));
  }

  setName(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {name: valueFromEvent(...value)}).notify();
  }

  setPercentage(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    value = valueFromEvent(...value);
    value = value * (this.percentageDiscount ? -1 : 1);
    return this.doc.updateItems(items, {percentage: value}).recalculate().notify();
  }

  setPosition(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {position: valueFromEvent(...value)}).notify();
  }

  setPriceMode(...value) {
    value = valueFromEvent(...value);
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    if (this.doc.isPresentation()
      && (this.isDecoration() || this.isAdditionalCost())
      && this.variantId != null) {
      // For an addon in a presentation we have to update all the columns, whether we are applying the same value to all,
      // or resetting to the defaults
      if (value === SalesDocItem.PriceMode.FLAT_RATE) {
        return this.doc.updateItems(items, {
          priceMode: value,
          additionalCost: this.additionalCost,
          buyPrice: this.buyPrice,
          buyPriceOverride: this.buyPriceOverride,
          markup: this.markup,
          markupOverride: this.markupOverride,
          unitPrice: this.unitPrice,
          unitPriceOverride: this.unitPriceOverride,
        }).recalculate().notify();
      } else {
        // When switching to quantity based pricing, we change the price mode in the first item,
        // and reset the price values to defaults in the other items.
        items.splice(items.indexOf(this), 1);
        return this.copyWith({priceMode: value}).#replace(this)
          .updateItems(items, {
            priceMode: value,
            additionalCost: 0,
            buyPrice: null,
            buyPriceOverride: false,
            markup: null,
            markupOverride: false,
            unitPrice: null,
            unitPriceOverride: false,
          })
          .recalculate()
          .notify();
      }
    } else {
      return this.doc.updateItems(items, {priceMode: value}).recalculate().notify();
    }
  }

  setProduct(product) {
    const items = this.variantId != null && this.productId ? this.doc.getVariantItems(this.variantId) : this;
    if (this.doc.products[product._id]) {
      product = this.doc.products[product._id];
    }
    return this.doc.updateItems(items, SalesDocItem.initVariantPropsFromProduct({salesDoc: this.doc, product}))
      .syncPresentationColumns()
      .addProduct(product); // No need for recalc or notify, addProduct will do it
  }

  setQuantity(...value) {
    value = valueFromEvent(...value);
    if (value !== this.quantity) {
      if (this.doc.isPresentation()) {
        return this.doc.setPresentationGroupColumnQuantity(this, value);
      } else {
        return this.copyWith({
          quantity: value,
          markup: this.markupOverride ? this.markup : null,
        }).#replace(this).clearMarkupsOnVariants(this.variantId).recalculate().notify();
      }
    } else {
      return this;
    }
  }

  setRollupSellPrice(value) {
    const rollupSellPrice = checkedFromEvent(value);
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    if (this.doc.isPresentation()) {
      const linkedItem = this.getDecorationItem() ?? this.getSetupCostItem();
      if (linkedItem && linkedItem.variantId) {
        items.push(...this.doc.getVariantItems(linkedItem.variantId));
      }
    }
    return this.doc.updateItems(items, {
      rollupSellPrice,
      ...(this.isAdditionalCost() ? {unitPriceOverride: false} : {}),
      ...(this.isSurchargeOrDiscount() && rollupSellPrice ? {applyAtCheckout: false} : {}),
    }).recalculate().notify();
  }

  setSetupCost(value) {
    return this.addSetupCostItem(value);
  }

  setSize(...value) {
    return this.copyWith({size: valueFromEvent(...value)}).#replace(this).recalculate().notify();
  }

  setSizes(sizes) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {sizes: sortSizes(sizes ?? [])}).recalculate().notify();
  }

  setShowItem(value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    if (this.doc.isPresentation()) {
      const linkedItem = this.getDecorationItem() ?? this.getSetupCostItem();
      if (linkedItem && linkedItem.variantId) {
        items.push(...this.doc.getVariantItems(linkedItem.variantId));
      }
    }

    return this.doc.updateItems(items, {showItem: checkedFromEvent(value)}).recalculate().notify();
  }

  setShowPricing(value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {showPricing: checkedFromEvent(value)}).recalculate().notify();
  }

  setTax(...tax) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {tax: valueFromEvent(...tax)}).recalculate().notify();
  }

  setUnitPrice(...value) {
    value = valueFromEvent(...value);
    const items = (this.type === SalesDocItem.Type.VARIANT && this.priceMode === SalesDocItem.PriceMode.AVERAGE) || this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {unitPrice: value, unitPriceOverride: value != null}).recalculate().notify();
  }

  setVendor(vendor) {
    if (this.doc.vendors[vendor._id] && this.doc.vendors[vendor._id].name) {
      vendor = this.doc.vendors[vendor._id];
    }
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {
      vendor: vendor,
      vendorId: vendor._id,
      vendorName: vendor.name
    })
      .addVendor(vendor);
  }

  // Helpers for populating

  static initVariantProps({salesDoc, type, groupId, variantId, catalog}) {
    return {
      groupId,
      variantId: variantId ?? SalesDoc.newVariantId(),
      type: type ?? SalesDocItem.Type.PRODUCT_PLACEHOLDER,
      linkedTo: salesDoc.isPresentation() ? SalesDocItem.LinkedTo.NONE : null,
      tax: salesDoc.template.tax ?? salesDoc.defaultTax,
      priceMode: salesDoc.template.variantPriceMode,
      showItem: salesDoc.template.showVariants,
      showPricing: salesDoc.template.showPricing,
      ...(catalog ? {catalog} : {}),
    };
  }

  static initVariantPropsFromProduct({salesDoc, product}) {
    return {
      type: SalesDocItem.Type.VARIANT,
      product: product,
      productId: product._id,
      code: product.code,
      ...(!salesDoc.isPresentation() ? {} : {
        colors: product.colors?.length > 0 ? [...product.colors] : undefined,
        sizes: product.sizes?.length > 0 ? [...product.sizes] : undefined,
        images: product.namedImages && Object.values(product.namedImages).length > 0
          ? uniq([product.primaryImage, ...Object.values(product.namedImages)]).filter(Boolean)
          : undefined,
      }),
      description: doTemplateSubstitution(salesDoc.template.productDescription, productSubstitutions, product),
      image: product.primaryImage ?? product.namedImages?.[0],
      name: product.title ?? '',
      vendor: product.vendor,
      vendorId: product.vendorId,
      vendorName: product.vendorName,
    };
  }

  // Helpers for updating

  #replace(replace) {
    return this.doc.replaceItem(replace, this);
  }
}
